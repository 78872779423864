import React, { Fragment, useEffect, useState } from "react";
import { Card, Col, CardBody } from "reactstrap";
import { H3, H6, Image, P, UL, Spinner } from "../../AbstractElements";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setDetail, setFilters } from "../../Features/Learning/Blog/blogSlice";
import { setField } from "../../Features/Learning/Blog/addBlogSlice";
import {
	fetchBlogs,
	handleDeleteBlog,
	handleEditBlog,
	handleGetDetailBlog,
	handleBackPagination,
	handleNextPagination,
	handleClickPagination,
} from "./BlogUtils";
import PaginationComponent from "./PaginationComponent";
import Moment from "moment";

const BlogBlox = () => {
	const dispatch = useDispatch();
	const filters = useSelector((state) => state.Blog.filters);
	const cards = useSelector((state) => state.Blog.data.data);
	const isLoading = useSelector((state) => state.Blog.isLoading);
	const pagination = useSelector((state) => state.Blog.data.pagination);
	const [refatch, setRefatch] = useState(false);

	useEffect(() => {
		fetchBlogs(dispatch, filters, refatch);
		window.scrollTo(0, 0);
	}, [refatch]);

	return (
		<Fragment>
			{isLoading ? (
				<Col lg="12">
					<div className="loader-box">
						<Spinner attrSpinner={{ className: "loader-30" }} />
					</div>
					<H6 attrH6={{ className: "sub-title mb-0 text-center" }}>
						{"Data Loading..."}
					</H6>
				</Col>
			) : cards && cards.length > 0 ? (
				cards &&
				cards.map((item) => {
					return (
						<Col
							xl="4"
							sm="4"
							lg="4"
							key={`tryout-${item.id}`}
							className="box-col-4">
							<Card>
								<div className="product-box learning-box">
									<div className="product-img">
										<Image
											attrImage={{
												className: "img-fluid top-radius-blog",
												src: item.thumbnail,
												alt: "",
											}}
										/>
										<div className="product-hover">
											<UL attrUL={{ className: "simple-list" }}>
												<li
													title="delete"
													onClick={() =>
														handleDeleteBlog(dispatch, item.id, setRefatch)
													}>
													<i className="icofont icofont-trash text-danger"></i>
												</li>
												<Link
													to={`${process.env.PUBLIC_URL}/blog-add/${item.id}`}
													onClick={() =>
														handleEditBlog(dispatch, item, setField)
													}>
													<li
														title="edit"
														style={{ marginBottom: "10px", marginTop: "10px" }}>
														<i className="icofont icofont-edit"></i>
													</li>
												</Link>
												<a
													href={`${process.env.REACT_APP_USER_URL}/blog/${item.slug}`}
													target="_blank"
													rel="noreferrer">
													<li title="view">
														<i className="icofont icofont-eye-alt"></i>
													</li>
												</a>
											</UL>
										</div>
									</div>
									<div className="details-main">
										<div className="bottom-details">
											<span>#{item.order} #{item.category}</span>
											<H6>{item.title}</H6>
										</div>
										<P>
											{item.description.length > 100
												? `${item.description.substring(0, 100)}...`
												: item.description}
										</P>
										<P>
											Created at :{" "}
											{Moment(item.created_at).format("ddd, DD/MM/YYYY")} -{" "}
											{Moment(item.created_at).format("HH:mm:ss")} WIB
										</P>
										{item.is_hide ? (
											<div className="bottom-details">
												<P>
													<i className="fa fa-eye-slash"></i> Sembunyi
												</P>
											</div>
										) : null}
									</div>
								</div>
							</Card>
						</Col>
					);
				})
			) : (
				<Col lg="12">
					<H3 attrH3={{ className: "sub-title mb-5 text-center" }}>
						{!cards ? "Sumber Data Tidak Tersedia" : "Data Tidak Ditemukan"}
					</H3>
				</Col>
			)}

			{isLoading === false && cards && cards.length > 0 && pagination && (
				<CardBody>
					<PaginationComponent
						pagination={pagination}
						handleBackPagination={() =>
							handleBackPagination(
								pagination,
								filters,
								dispatch,
								setFilters,
								setRefatch
							)
						}
						handleClickPagination={(page) =>
							handleClickPagination(
								page,
								filters,
								dispatch,
								setFilters,
								setRefatch
							)
						}
						handleNextPagination={() =>
							handleNextPagination(
								pagination,
								filters,
								dispatch,
								setFilters,
								setRefatch
							)
						}
					/>
				</CardBody>
			)}
		</Fragment>
	);
};

export default BlogBlox;
