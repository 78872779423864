import React, { Fragment, useEffect, useState } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import TicketDetailContain from '../../../Component/Learning/Ticket/TicketDetail';
import { useParams } from 'react-router-dom';
import { fetchTicket } from '../../../Component/Learning/Ticket/TicketUtils';
import { useDispatch, useSelector } from 'react-redux';

const TicketDetail = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const filters = useSelector(state => state.Ticket.filters)
    const [refatch, setRefatch] = useState(false);

    const newFilters = {
        ...filters,
        "filter[id]": id,
        "page": 1,
    };

    useEffect(() => {
        fetchTicket(dispatch, newFilters)
    }, [refatch])

    return (
        <Fragment>
            <Breadcrumbs parent="Laporan Soal" title="Detail Laporan" />
            <TicketDetailContain refatch={refatch} setRefatch={setRefatch} />
        </Fragment>
    );
};
export default TicketDetail;