import React, { Fragment, useEffect } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import DetailsCourseContain from '../../../Component/Learning/Courses/DetailCourse';
import { setDetail, setFilters } from '../../../Features/Learning/Courses/courseSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { handleGetDetailCourse } from '../../../Component/Learning/Courses/CoursesUtils';
import { fetchCourses } from '../../../Features/Learning/Courses/Utils/CoursesUtils';


const DetailCourse = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const filters = useSelector(state => state.Course.filters)

  useEffect(() => {
    if (id) {
      const newFilters = {
        ...filters,
        "filter[id]": id,
        "page": 1,
      };
      dispatch(fetchCourses(newFilters))
        .then((response) => {
          // Kode yang akan dijalankan setelah fetch sukses
          handleGetDetailCourse(dispatch, response.payload.data[0], setDetail)
        })
        .catch((error) => {
          // Tangani error jika fetch gagal
          console.error('Error during fetchCourses:', error);
        });
    }
  } , [id]);

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Detail Course" parent="Membership" title="Detail Course" />
      <DetailsCourseContain />
    </Fragment>
  );
};
export default DetailCourse;