import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Col, Tooltip } from 'reactstrap';
import RawComponent from '../RawHTML';
import VideoPlayer from '../Player/VideoPlayer';

const Example = ({ title, type, content, buttonLabel, buttonSize, size, messageTooltip }) => {
    const [modal, setModal] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
    // console.log(content);

    const toggle = () => setModal(!modal);

    return (
        <div>
            <Button color="primary" onClick={toggle} size={buttonSize} id="Tooltip-1">
                {buttonLabel}
            </Button>
            <Tooltip placement="top" isOpen={tooltipOpen} target="Tooltip-1" toggle={toggleTooltip}>
                {messageTooltip}
            </Tooltip>
            <Modal isOpen={modal} toggle={toggle} size={size}>
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody>
                    {type === "video" && (
                        <Col sm="12" className="mb-3 text-center d-flex justify-content-center">
                            <VideoPlayer link={content} width={720} />
                        </Col>
                    )}
                    {type === "html" && (
                        <RawComponent htmlContent={content} />
                    )}
                    {type === "text" && (
                        <p>{content}</p>
                    )}
                    {type === "image" && (
                        <Col sm="12" className="mb-3 text-center d-flex justify-content-center">
                            <img src={content} alt={title} className="img-fluid" 
                                style={{
                                    borderRadius: '10px', // Atur sudut
                                    width: 'auto', // Agar responsif
                                    height: 'auto', // Menjaga proporsi gambar
                                    objectFit: 'cover' // Menjaga tampilan gambar
                                }} 
                            />
                        </Col>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="warning" onClick={toggle}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default Example;