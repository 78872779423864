import { createAsyncThunk } from '@reduxjs/toolkit';
import { MembershipServices } from '../../../../Services/axios/Membership.Services';
import { toast } from 'react-toastify';
import Swal from "sweetalert2";

export const fetchMemberships = createAsyncThunk(
    'Memberships/fetchMemberships',
    async (filters) => {
        try {
        const response = await MembershipServices.getAll(filters);
        return response.data;
        } catch (error) {
            if (error.response) {
                const statusCode = error.response.status;
                let errorMessage = 'Terjadi kesalahan saat mengambil data.';

                if (statusCode === 400) {
                    errorMessage = 'Permintaan tidak valid.';
                } else if (statusCode === 401) {
                    errorMessage = 'Anda tidak diotorisasi untuk mengakses sumber daya ini.';
                } else if (statusCode === 404) {
                    errorMessage = 'Sumber daya tidak ditemukan.';
                } else if (statusCode === 500) {
                    errorMessage = 'Terjadi kesalahan internal server.';
                } else if (statusCode === 0) {
                    errorMessage = 'Tidak ada koneksi internet.';
                }

                Swal.fire({
                    icon: 'error',
                    title: statusCode === 0 ? 'Error' : `Error ${statusCode}`,
                    text: errorMessage,
                });
            } else if (error.request) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Permintaan gagal. Mohon periksa koneksi internet Anda.',
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Terjadi kesalahan. Mohon coba lagi nanti.',
                });
            }
            throw error;
        }
    }
    );

export const submitMemberships = createAsyncThunk(
    'Memberships/submitMemberships',
    async (data) => {
        try {
        const formData = new FormData();
        formData.append('title', data.title);
        formData.append('price', data.price);
        formData.append('gimmick_price', data.gimmick_price);
        formData.append('is_price_bottom', data.is_price_bottom);
        formData.append('subheader_desc', data.subheader_desc);
        formData.append('duration_days', data.duration_days);
        formData.append('is_to_only', data.is_to_only);
        formData.append('is_online', data.is_online);
        formData.append('is_guaranteed', data.is_guaranteed);
        formData.append('main_program_id', data.main_program_id);
        data.link && formData.append('link', data.link);
        data.points.forEach((point, index) => {
            const data = {
                name: point.name,
                status: point.status
            }
            formData.append(`points[${index}]`, JSON.stringify(data));
        });
        
        let response;
        if (data.isUpdate) {
            response = await MembershipServices.update(data.id, formData);
            toast.success('Membership berhasil diperbarui', {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            response = await MembershipServices.create(formData);
            toast.success('Membership berhasil ditambahkan', {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        data.successCallback && data.successCallback();
        return response.data.data;
        } catch (error) {
            for (const errorField in error.response.data.errors) {
                if (error.response.data.errors.hasOwnProperty(errorField)) {
                    const errorMessages = error.response.data.errors[errorField];
                    let errorMessage = errorMessages[0];
                    toast.error(errorMessage, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            }
            if (error.code === "ERR_NETWORK") {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Permintaan gagal. Mohon periksa koneksi internet Anda.',
                });
            } else if (error.response.status === 500) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Terjadi kesalahan sistem. Mohon hubungi pengembang.',
                });
            }
        }
    }
    );

export const deleteMemberships = createAsyncThunk(
    'Memberships/deleteMemberships',
    async (id) => {
        try {
        const response = await MembershipServices.delete(id);
        toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT
        });
        return id;
        } catch (error) {
        if (error.response && error.response.data) {
            toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT
            });
        }
        }
    }
);

export const attachDetachCourseMemberships = createAsyncThunk(
    'Membership/attachDetachCourseMemberships',
    async (data) => {
        try {
        const formData = new FormData();
        data.courses.forEach((course, index) => {
          formData.append(`course[${index}][id]`, course.value);
        });
        let response;
        response = await MembershipServices.attachDetachCourse(data.id, formData);
        return true;
        } catch (error) {
        if (error.response && error.response.data) {
            toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT
            });
        }
        return false;
        }
    }
    );

export const attachDetachTryoutMemberships = createAsyncThunk(
    'Membership/attachDetachTryoutMemberships',
    async (data) => {
        try {
        const formData = new FormData();
        data.tryouts.forEach((tryout, index) => {
          formData.append(`tryout[${index}][id]`, tryout.value);
        });
        let response;
        response = await MembershipServices.attachDetachTryout(data.id, formData);
        return true;
        } catch (error) {
        if (error.response && error.response.data) {
            toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT
            });
        }
        return false;
        }
    }
    );

export const attachUserMemberships = createAsyncThunk(
    'Membership/attachUserMemberships',
    async (data) => {
        try {
        const formData = new FormData();
        formData.append('user_id', data.user_id);
        let response;
        response = await MembershipServices.attachUser(data.id, formData);
        // toast.success('Berhasil tambah user ke course', {
        //   position: toast.POSITION.TOP_RIGHT
        // });
        return true;
        } catch (error) {
        if (error.response && error.response.data) {
            toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT
            });
        }
        return false;
        }
    }
    );
    
export const detachUserMemberships = createAsyncThunk(
    'Membership/detachUserMemberships',
    async (data) => {
        // console.log(data)
        try {
        const formData = new FormData();
        formData.append('membership_user_id', data.membership_user_id);
        let response;
        response = await MembershipServices.detachUser(data.id, formData);
        // toast.success('Berhasil hapus user dari course', {
        //   position: toast.POSITION.TOP_RIGHT
        // });
        return true;
        } catch (error) {
        if (error.response && error.response.data) {
            toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT
            });
        }
        return false;
        }
    }
);

export const upgradeUserMembership = createAsyncThunk(
    'Membership/upgradeUserMembership',
    async (data) => {
        try {
            const formData = new FormData();
            formData.append('user_id', data.user_id);
            formData.append('membership_id_old', data.membership_id_old);
            formData.append('membership_id_new', data.membership_id_new);
            let response;
            console.log('data', data)
            response = await MembershipServices.upgradeUserMembership(formData);
            return true;
        } catch (error) {
            if (error.response && error.response.data) {
                toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT
                });
            }
            return false;
        }
    }
);

export const replicateMemberships = createAsyncThunk(
    'Memberships/replicateMemberships',
    async (id) => {
        try {
            const response = await MembershipServices.replicate(id);
            return response;
        } catch (error) {
            if (error.response && error.response.data) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }
);