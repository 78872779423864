import {
	BlogSvg,
	BonusUISvg,
	ButtonsSvg,
	CalanderSvg,
	ChartsSvg,
	ChatSvg,
	ContactSvg,
	EcommerceSvg,
	EditorsSvg,
	EmailSvg,
	FAQSvg,
	FilemanagerSvg,
	FormsSvg,
	GallarySvg,
	HeaderBookmarkSvg,
	HomeSvg,
	IconsSvg,
	JobsearchSvg,
	KanbanSvg,
	KnowledgebaseSvg,
	LearningSvg,
	MapsSvg,
	OthersSvg,
	ProjectSvg,
	SamplePageSvg,
	SearchResultSvg,
	SocialappSvg,
	SupportTicketSvg,
	TablesSvg,
	TaskSvg,
	TodoSvg,
	UiKitsSvg,
	UsersComponentSvg,
	WidgetsSvg,
} from "../../Data/svgIcons";

export const MENUITEMS = [
	{
		menutitle: "DashBoards",
		Items: [
			// {
			// title: 'Dashboards', icon: HomeSvg, type: 'sub', badge: false, active: false, children: [
			// { path: `${process.env.PUBLIC_URL}/dashboard/default`, title: 'Default', type: 'link' },
			{
				path: `${process.env.PUBLIC_URL}/dashboard/default`,
				icon: HomeSvg,
				type: "link",
				active: window.location.pathname.includes("/Dashboards"),
				title: "Dashboards",
			},
			// { path: `${process.env.PUBLIC_URL}/dashboard/ecommerce`, title: 'Ecommerce', type: 'link' },
			// { path: `${process.env.PUBLIC_URL}/dashboard/crypto`, title: 'Crypto', type: 'link' },
			// ]
			// },
		],
	},
	{
		Items: [
			{
				title: "Learning Products",
				icon: LearningSvg,
				type: "sub",
				active: window.location.pathname.includes("/learning/   "),
				children: [
					{
						path: `${process.env.PUBLIC_URL}/membership/course-list`,
						title: "Course",
						type: "link",
					},
					{
						title: "Materi",
						type: "sub",
						active:
							window.location.pathname.includes("/learning/materi") ||
							window.location.pathname.includes("/learning/sub-materi "),
						children: [
							{
								path: `${process.env.PUBLIC_URL}/learning/materi-list`,
								title: "Materi",
								type: "link",
							},
							{
								path: `${process.env.PUBLIC_URL}/learning/sub-materi-list`,
								title: "Submateri",
								type: "link",
							},
						],
					},
					{
						path: `${process.env.PUBLIC_URL}/learning/category-list`,
						title: "Kategori TO",
						type: "link",
					},
					{
						path: `${process.env.PUBLIC_URL}/learning/tryout-list`,
						title: "Tryout",
						type: "link",
					},
					{
						path: `${process.env.PUBLIC_URL}/learning/soal-list`,
						title: "Bank Soal",
						type: "link",
					},
				],
			},
		],
	},
	{
		menutitle: "Marketing Products",
		Items: [
			{
				title: "Marketing Products",
				icon: EcommerceSvg,
				type: "sub",
				active: window.location.pathname.includes("/membership/ "),
				children: [
					{
						path: `${process.env.PUBLIC_URL}/main-program`,
						title: "Program Utama",
						type: "link",
					},
					{
						path: `${process.env.PUBLIC_URL}/membership/membership-list`,
						title: "Membership",
						type: "link",
					},
					{
						path: `${process.env.PUBLIC_URL}/user-testimonials`,
						title: "Ulasan Pengguna",
						type: "link",
					},
				],
			},
		],
	},
	{
		menutitle: "Class",
		Items: [
			{
				title: "Kelas",
				icon: EditorsSvg,
				type: "sub",
				active: window.location.pathname.includes("/classes/ "),
				children: [
					{
						path: `${process.env.PUBLIC_URL}/classes/class-list`,
						title: "Daftar Kelas",
						type: "link",
					},
					{
						path: `${process.env.PUBLIC_URL}/classes/tentor-list`,
						title: "Daftar Tentor",
						type: "link",
					},
					{
						path: `${process.env.PUBLIC_URL}/classes/teaching-history`,
						title: "Riwayat Mengajar",
						type: "link",
					},
					{
						path: `${process.env.PUBLIC_URL}/classes/qr-presence-class`,
						title: "QR Code Presensi",
						type: "link",
					},
				],
			},
		],
	},
	{
		menutitle: "Voucher",
		Items: [
			{
				path: `${process.env.PUBLIC_URL}/voucher`,
				icon: CalanderSvg,
				type: "link",
				active: window.location.pathname.includes("/voucher "),
				title: "Voucher",
			},
		],
	},
	{
		menutitle: "User Brofesional",
		Items: [
			{
				title: "User Brofesional",
				icon: UsersComponentSvg,
				type: "sub",
				active: window.location.pathname.includes("/users/ "),
				children: [
					{
						path: `${process.env.PUBLIC_URL}/users/user-list`,
						title: "Daftar Pengguna",
						type: "link",
					},
					{
						path: `${process.env.PUBLIC_URL}/users/referrer-list`,
						title: "Daftar Referrer",
						type: "link",
					},
				],
			},
		],
	},
	{
		menutitle: "Laporan Soal",
		Items: [
			{
				path: `${process.env.PUBLIC_URL}/user-tickets`,
				icon: SupportTicketSvg,
				type: "link",
				active: window.location.pathname.includes("/user-tickets "),
				title: "Laporan Soal",
			},
		],
	},
	{
		menutitle: "Daftar Transaksi",
		Items: [
			{
				path: `${process.env.PUBLIC_URL}/transactions`,
				icon: ChartsSvg,
				type: "link",
				active: window.location.pathname.includes("/transactions "),
				title: "Daftar Transaksi",
			},
		],
	},
	{
		menutitle: "Blogs",
		Items: [
			{
				path: `${process.env.PUBLIC_URL}/blog-list`,
				icon: SamplePageSvg,
				type: "link",
				active: window.location.pathname.includes("/blog-list "),
				title: "Blog",
			},
		],
	},
];
