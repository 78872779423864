import React, { Fragment, useEffect, useState } from "react";
import {
	Col,
	Card,
	CardBody,
	CardHeader,
	CardFooter,
	Table,
	Row,
	Label,
} from "reactstrap";
import { Minus } from "react-feather";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
	H6,
	H5,
	Btn,
	Spinner,
	Badges,
	Image,
} from "../../../../AbstractElements";
import {
	setDetail,
	setFilters,
} from "../../../../Features/Classes/Schedule/Teaching/TeachingSlice";
import {
	fetchPrivateSchedule,
	handleNextPagination,
	handleBackPagination,
	handleClickPagination,
} from "./TeachingUtils";
import PaginationComponent from "../../../Learning/Common/Pagination";
import ScheduleFilter from "./Filter/FindTeaching";
import Moment from "moment";
import "moment/locale/id";
import ModalComponent from "../../../Learning/Common/Modal/ModalGeneral";

const TeachingScheduleContain = () => {
	const { classId } = useParams();
	const dispatch = useDispatch();
	const filters = useSelector((state) => state.Teaching.filters);
	const isLoading = useSelector((state) => state.Teaching.isLoading);
	const data = useSelector((state) => state.Teaching.data.data);
	const pagination = useSelector((state) => state.Teaching.data.pagination);
	const [refatch, setRefatch] = useState(false);

	useEffect(() => {
		fetchPrivateSchedule(dispatch, filters, refatch);
	}, [refatch]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Fragment>
			<Col sm="12 order-2">
				<Card className="px-3">
					<ScheduleFilter />
				</Card>
				{isLoading ? (
					<Col lg="12">
						<div className="loader-box">
							<Spinner attrSpinner={{ className: "loader-30" }} />
						</div>
						<H6 attrH6={{ className: "mb-0 text-center" }}>
							{"Data Loading..."}
						</H6>
					</Col>
				) : data && data.length === 0 ? (
					<Card>
						<CardHeader>
							<H6 attrH6={{ className: "mb-0 text-center" }}>
								{filters["filter[name]"] ||
								filters["filter[place]"] ||
								filters["filter[tentor_id]"]
									? "Sesi tidak ditemukan"
									: "Belum ada sesi terjadwal"}
							</H6>
						</CardHeader>
					</Card>
				) : (
					<Col sm="12" xl="12" lg="12" className="xl-100 box-col-12 order-1">
						<Card className="social-widget-card">
							<CardBody>
								<Table className="table-bordered table-striped">
									<thead>
										<tr>
											<th
												scope="col"
												className="bg-primary text-light"
												style={{ width: "50px", verticalAlign: "middle" }}>
												#
											</th>
											<th
												scope="col"
												className="bg-primary text-light"
												style={{ verticalAlign: "middle" }}>
												Nama Tentor
											</th>
											<th
												scope="col"
												className="bg-primary text-light text-center"
												style={{ verticalAlign: "middle" }}>
												Peserta
											</th>
											<th
												scope="col"
												className="bg-primary text-light text-center"
												style={{ verticalAlign: "middle" }}>
												Durasi Rencana/Aktual
											</th>
											<th
												scope="col"
												className="bg-primary text-light text-center"
												style={{ verticalAlign: "middle" }}>
												Status
											</th>
											<th
												scope="col"
												className="bg-primary text-light text-center"
												style={{ width: "30%", verticalAlign: "middle" }}>
												Check in / Check out
											</th>
											<th
												scope="col"
												className="bg-primary text-light text-center"
												style={{ width: "50px", verticalAlign: "middle" }}>
												Note
											</th>
										</tr>
									</thead>
									<tbody>
										{data &&
											data.map((item, index) => (
												<tr key={index}>
													<th scope="row">{pagination.from + index}</th>
													<td title={item.tentor.name}>
														<Link
															to={`/classes/tentor/detail/${item.tentor.id}`}>
															<b>
																{item.tentor.name.length > 15
																	? `${item.tentor.name.substring(0, 15)}...`
																	: item.tentor.name}
															</b>
														</Link>
													</td>
													<td className="text-center">
														{item.tentor_member && (
															<>
																{item.tentor_member.name} -{" "}{ 
																	item.tentor_member.type}
																<br/><b>{item.tentor_member.total_student}</b>
															</>
														)}
													</td>
													<td className="text-center">
														<b
															className={
																item.duration >
																Moment(item.check_out_time).diff(
																	item.check_in_time,
																	"minutes"
																)
																	? "text-danger"
																	: ""
															}>
															{item.duration} /{" "}
															{item.check_out_time
																? Moment(item.check_out_time).diff(
																		item.check_in_time,
																		"minutes"
																  )
																: "-"}{" "}
															menit
														</b>
													</td>
													<td className="text-center">
														<b>
															{item.check_out_time
																? "Selesai"
																: "Belum Check Out"}
														</b>
													</td>
													<td>
														<Row className="d-flex justify-content-between">
															<Col>
																<div>
																	<Image
																		attrImage={{
																			style: {
																				width: "60px",
																				height: "auto",
																				borderRadius: "10%",
																				border: "1px solid #eee",
																				marginRight: "5px",
																			},
																			src: item.check_in_photo,
																			alt: "",
																		}}
																	/>
																</div>
															</Col>
															<Col>
																<div>
																	<ModalComponent
																		title="Check in photo"
																		type="image"
																		buttonLabel={
																			<p style={{ fontWeight: "bold" }}>
																				{item.check_in_time
																					? Moment(item.check_in_time).format(
																							"DD-MM-YYYY HH:mm:ss"
																					  )
																					: "-"}
																			</p>
																		}
																		buttonSize="xs"
																		size="md"
																		content={item.check_in_photo}
																	/>
																</div>
																<div>
																	<Label>
																		<a
																			href={`https://www.google.com/maps/search/?api=1&query=${item.check_in_location}`}
																			target="_blank"
																			className="text-success badge"
																			title="Lokasi Check-In">
																			{item.check_in_location.length > 20
																				? `${item.check_in_location.substring(
																						0,
																						20
																				  )}...`
																				: item.check_in_location}
																		</a>
																	</Label>
																</div>
															</Col>
														</Row>
														{item.check_out_time ? (
															<Row className="d-flex justify-content-between">
																<Col>
																	<div>
																		<Image
																			attrImage={{
																				style: {
																					width: "60px",
																					height: "auto",
																					borderRadius: "10%",
																					border: "1px solid #eee",
																					marginRight: "5px",
																				},
																				src: item.check_out_photo,
																				alt: "",
																			}}
																		/>
																	</div>
																</Col>
																<Col>
																	<div>
																		<ModalComponent
																			title="Check out photo"
																			type="image"
																			buttonLabel={
																				<p style={{ fontWeight: "bold" }}>
																					{item.check_out_time
																						? Moment(
																								item.check_out_time
																						  ).format("DD-MM-YYYY HH:mm:ss")
																						: "-"}
																				</p>
																			}
																			buttonSize="xs"
																			size="md"
																			content={item.check_out_photo}
																		/>
																	</div>
																	<div>
																		<Label>
																			<a
																				href={`https://www.google.com/maps/search/?api=1&query=${item.check_oout_location}`}
																				target="_blank"
																				className="text-success badge"
																				title="Lokasi Check-Out">
																				{item.check_out_location.length > 20
																					? `${item.check_out_location.substring(
																							0,
																							20
																					  )}...`
																					: item.check_out_location}
																			</a>
																		</Label>
																	</div>
																</Col>
															</Row>
														) : (
															<div className="text-center">
																<Minus size={25} className="text-dark me-1" />
															</div>
														)}
													</td>
													<td>
														{item.note ? (
															<ModalComponent
																title={`Catatan sesi privat: ${item.tentor.name}`}
																type="text"
																content={item.note}
																buttonLabel={
																	<i className="fa fa-info-circle fa-lg"></i>
																}
																buttonSize="xs"
																size="md"
																messageTooltip="Preview"
															/>
														) : (
															<Minus size={25} className="text-dark me-1" />
														)}
													</td>
												</tr>
											))}
									</tbody>
								</Table>
							</CardBody>
							<CardFooter>
								{pagination && (
									<PaginationComponent
										pagination={pagination}
										handleBackPagination={() =>
											handleBackPagination(
												pagination,
												filters,
												dispatch,
												setFilters,
												setRefatch
											)
										}
										handleClickPagination={(page) =>
											handleClickPagination(
												page,
												filters,
												dispatch,
												setFilters,
												setRefatch
											)
										}
										handleNextPagination={() =>
											handleNextPagination(
												pagination,
												filters,
												dispatch,
												setFilters,
												setRefatch
											)
										}
									/>
								)}
							</CardFooter>
						</Card>
					</Col>
				)}
			</Col>
		</Fragment>
	);
};
export default TeachingScheduleContain;
