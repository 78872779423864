import React, { Fragment, useEffect, useState } from "react";
import {
	Col,
	Card,
	CardBody,
	CardHeader,
	CardFooter,
	Table,
	Row,
	Form,
	FormGroup,
	Label,
	Input,
	Modal,
	ModalHeader,
	ModalBody,
	Button,
} from "reactstrap";
import Select from "react-select";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
	Image,
	H6,
	H5,
	H4,
	LI,
	UL,
	P,
	Btn,
} from "../../../../AbstractElements";
import {
	setField,
	resetState,
} from "../../../../Features/Classes/TentorMember/addTentorMemberSlice";
import { handleGetDetailTentor } from "../../../../Component/Classes/Tentor/TentorUtils";
import { fetchTentors } from "../../../../Features/Classes/Tentor/Utils/TentorUtils";
import { setDetail } from "../../../../Features/Classes/Tentor/TentorSlice";
import {
	submitTentorMember,
	deleteTentorMember,
} from "../../../../Features/Classes/TentorMember/Utils/TentorMemberUtils";
import Swal from "sweetalert2";

const TentorStudents = () => {
	const dispatch = useDispatch();
	const detail = useSelector((state) => state.Tentor.detail);
	const addState = useSelector((state) => state.addTentorMember);
	const [isOpen, setIsOpen] = useState(false);
	const [refatch, setRefatch] = useState(false);
	const { id } = useParams();
	const filters = useSelector((state) => state.Tentor.filters);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		// fetch data detail tentor
		if (id && refatch) {
			const newFilters = {
				...filters,
				"filter[id]": id,
				page: 1,
			};
			dispatch(fetchTentors(newFilters))
				.then((response) => {
					handleGetDetailTentor(dispatch, response.payload.data[0], setDetail);
				})
				.catch((error) => {
					console.error("Error during fetchTentor on Detail:", error);
				});
			setRefatch(false);
		}
	}, [refatch]);

	const options = [
		{ value: "private", label: "Private" },
		{ value: "group", label: "Group" },
		{ value: "class", label: "Class" },
	];

	const handleChangeAddTentorMember = (field, value) => {
		dispatch(setField({ field: field, value: value }));
	};

	const handleSubmit = () => {
		dispatch(submitTentorMember(addState));
		setIsLoading(true);
		setTimeout(() => {
			setRefatch(true);
			setIsOpen(!isOpen);
			setIsLoading(false);
		}, 1000);
	};

	const handleOpenEdit = (data) => {
		dispatch(setField({ field: "isUpdate", value: true }));
		dispatch(setField({ field: "id", value: data.id }));
		dispatch(setField({ field: "name", value: data.name }));
		dispatch(setField({ field: "type", value: data.type }));
		dispatch(setField({ field: "total_student", value: data.total_student }));
		setIsOpen(!isOpen);
	};

	const handleOpenAdd = () => {
		dispatch(resetState());
		dispatch(setField({ field: "tentor_id", value: detail.id }));
		setIsOpen(true);
	};

	const handleDelete = (id) => {
		Swal.fire({
			title: "Apakah anda yakin?",
			text: "Anda tidak dapat mengembalikan data yang telah dihapus!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
		}).then(async (result) => {
			if (result.isConfirmed) {
				await dispatch(deleteTentorMember(id));
				setRefatch((prevRefatch) => !prevRefatch);
				Swal.fire("Terhapus!", "Data telah dihapus.", "success");
			}
		});
	};

	return (
		<>
			<Fragment>
				<Col sm="12" xl="12" lg="12" className="xl-100 box-col-12 order-1">
					<Card className="social-widget-card">
						<CardHeader>
							<Col>
								<Row>
									<Col>
										<h5>{"Daftar Siswa"}</h5>
									</Col>
								</Row>
							</Col>
						</CardHeader>
						<CardBody>
							<Row>
								<Table>
									<thead className="table-light">
										<tr>
											<th scope="col">{"#"}</th>
											<th scope="col">{"Nama"}</th>
											<th scope="col">{"Tipe"}</th>
											<th scope="col">{"Jumlah Siswa"}</th>
											<th scope="col" width="250px" className="text-center">
												Aksi
											</th>
										</tr>
									</thead>
									<tbody>
										{detail.tentor_member.map((item, index) => (
											<tr key={index}>
												<th scope="row">{index + 1}</th>
												<td>{item.name}</td>
												<td>{item.type}</td>
												<td>{item.total_student}</td>
												<td className="text-center">
													<Btn
														attrBtn={{
															color: "",
															className: "btn btn-sm",
															onClick: () => handleOpenEdit(item),
														}}>
														<i className="fa fa-edit mx-auto text-warning fa-lg"></i>
													</Btn>
													<Btn
														attrBtn={{
															color: "",
															className: "btn btn-sm",
															onClick: () => handleDelete(item.id),
														}}>
														<i className="fa fa-trash text-danger fa-lg"></i>
													</Btn>
												</td>
											</tr>
										))}
										<tr>
											<td colSpan="5" className="text-center">
												<div onClick={handleOpenAdd}>
													<Btn
														attrBtn={{
															color: "primary",
															className: "btn btn-primary m-r-10",
														}}>
														<i className="fa fa-plus-circle fa-lg me-2"></i>
														Tambah Siswa
													</Btn>
												</div>
											</td>
										</tr>
									</tbody>
								</Table>
							</Row>
						</CardBody>
					</Card>
				</Col>
			</Fragment>
			<Modal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} size="lg">
				<Fragment>
					<ModalHeader toggle={() => setIsOpen(!isOpen)}>
						{addState.isUpdate ? "Perbarui Siswa" : "Tambah Siswa"}
					</ModalHeader>
					<ModalBody>
						<Form className="row needs-validation">
							<Col sm="12">
								<FormGroup className="mb-3">
									<Label for="validationName" style={{ fontWeight: "bold" }}>
										Tipe
									</Label>
									<Select
										options={options}
										className="js-example-basic-single"
										isMulti={false}
										isClearable={true}
										placeholder="Tipe..."
										defaultValue={{
											value: addState.type,
											label: addState.type,
										}}
										onChange={(e) =>
											handleChangeAddTentorMember("type", e.value)
										}
									/>
									<div className="valid-feedback">{"Looks good!"}</div>
								</FormGroup>
								<FormGroup className="mb-3">
									<Label for="validationName" style={{ fontWeight: "bold" }}>
										Nama:
									</Label>
									<Input
										className="form-control"
										id="validationName"
										type="text"
										name="name"
										placeholder="Masukkan Nama Siswa/Grup/Kelas"
										value={addState.name}
										onChange={(e) =>
											handleChangeAddTentorMember("name", e.target.value)
										}
									/>
								</FormGroup>
								<FormGroup className="mb-3">
									<Label for="validationName" style={{ fontWeight: "bold" }}>
										Jumlah Siswa:
									</Label>
									<Input
										className="form-control"
										id="validationName"
										type="number"
										name="total_student"
										placeholder="Masukkan Jumlah Siswa"
										value={addState.total_student}
										onChange={(e) =>
											handleChangeAddTentorMember(
												"total_student",
												e.target.value
											)
										}
									/>
								</FormGroup>
								{!isLoading && (
									<Btn
										attrBtn={{
											color: "primary",
											className: "btn btn-primary m-r-10",
											onClick: () => handleSubmit(),
										}}>
										<i className="fa fa-plus-circle fa-lg me-2"></i>
										{addState.isUpdate ? "Perbarui" : "Tambah"}
									</Btn>
								)}
							</Col>
						</Form>
					</ModalBody>
				</Fragment>
			</Modal>
		</>
	);
};
export default TentorStudents;
