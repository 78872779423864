import { fetchTryout, deleteTryout, replicateTryout } from '../../../Features/Learning/Tryout/Utils/TryoutUtils';
import Swal from 'sweetalert2';

/* **************************************************** */
/* CRUD Tryout                                       */
/* **************************************************** */

export const fetchTryouts = (dispatch, filters, refatch) => {
  dispatch(fetchTryout(filters));
};

export const handleDeleteTryout = async (dispatch, id, setRefetch) => {
  try {
    Swal.fire({
      title: 'Apakah anda yakin?',
      text: "Anda tidak dapat mengembalikan data yang telah dihapus!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33'
    }).then(async (result) => {
      if (result.isConfirmed) {
        await dispatch(deleteTryout(id));
        setRefetch(prevRefetch => !prevRefetch);
        Swal.fire(
          'Terhapus!',
          'Data telah dihapus.',
          'success'
        )
      }
    })
  } catch (error) {
    console.error("Delete error:", error);
  }
};

export const handleEditTryout = async (dispatch, item, setField) => {
  const fieldsToUpdate = ['title', 'description', 'time', 'start_at', 'end_at', 'quotas', 'category', 'is_quiz', 'instruction', 'is_report_show'];
  fieldsToUpdate.forEach(field => {
    dispatch(setField({ field, value: item[field] }));
  });
  dispatch(setField({ field: 'ads_videoLink', value: item.ads_video }));
  dispatch(setField({ field: 'tutorial_videoLink', value: item.tutorial_video }));
  dispatch(setField({ field: 'bannerLink', value: item.banner }));
  dispatch(setField({ field: 'isUpdate', value: true }));
  dispatch(setField({ field: 'id', value: item.id }));
};

export const handleGetDetailTryout = async (dispatch, item, setDetail) => {
  // console.log('item:', item);
    dispatch(setDetail(item));
};

export const handleDetach = async (detail, setDetail, item, data, dispatch, detachTryout ,setRefetch) => {
  const newDetail = { ...detail };
  try {
    Swal.fire({
      title: 'Apakah anda yakin?',
      text: "Anda tidak dapat mengembalikan data yang telah dihapus!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const success = await dispatch(detachTryout(data))
        if (success.payload === true) {
          setRefetch(prevRefetch => !prevRefetch);
          newDetail.question = newDetail.question.filter((question) => question.id !== item.pivot['question_id']);
          Swal.fire(
            'Terhapus!',
            'Data telah dihapus.',
            'success'
          )
          dispatch(setDetail(newDetail));
        } else if (success.payload === false) {
          Swal.fire(
            'Gagal!',
            'Data gagal dihapus.',
            'error'
          )
        }
        
      }
    })
  } catch (error) {
    console.error("Delete error:", error);
  }

};

export const handleReplicateTryout = async (dispatch, item, setRefetch) => {
  try {
    const { value: formValues } = await Swal.fire({
      title: 'Pilih opsi duplikasi',
      html:
        '<input type="radio" id="withoutQuestions" name="duplicationOption" value="1">' +
        '<label for="withoutQuestions">Tanpa soal</label><br>' +
        '<input type="radio" id="withQuestions" name="duplicationOption" value="0">' +
        '<label for="withQuestions">Dengan soal</label><br>' +
        '<input type="text" id="category_id" class="swal2-input" placeholder="Category ID untuk semua Soal">' +
        '<p>lihat list kategori <a href="/learning/category-list">disini</a></p>',
      focusConfirm: false,
      preConfirm: () => {
        const duplicationOption = document.querySelector('input[name="duplicationOption"]:checked').value;
        const categoryId = document.getElementById('category_id').value;
        if (!duplicationOption) {
          Swal.showValidationMessage('Anda harus memilih salah satu opsi!');
        }
        if (!categoryId && duplicationOption === '0') {
          Swal.showValidationMessage('Anda harus memasukkan Category ID!');
        }
        return { duplicationOption, categoryId };
      }
    });

    // Jika pengguna telah memilih opsi, lanjutkan ke konfirmasi akhir
    console.log(formValues);
    if (formValues) {
      const { duplicationOption: withoutQuestions, categoryId } = formValues;
      const optionText = withoutQuestions === '1' ? 'tanpa soal' : 'dengan soal';
      Swal.fire({
        title: 'Apakah anda yakin?',
        html: `Anda akan menduplikasi Tryout <strong>${item.title}</strong> ini ${optionText}, <strong>${categoryId ? 'dengan kategori ID ' + categoryId : '--Tanpa Kategori'}</strong>.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await dispatch(replicateTryout({ id: item.id, isReplicateContainerOnly: withoutQuestions, category_id: categoryId }));
          if (response.payload.status === 201) {
            setRefetch(prevRefetch => !prevRefetch);
            Swal.fire(
              'Berhasil!',
              'Data telah diduplikasi.',
              'success'
            );
          } else {
            Swal.fire(
              'Gagal!',
              'Data gagal diduplikasi.',
              'error'
            );
          }
        }
      });
    }
  } catch (error) {
    console.error("Replicate error:", error);
  }
};

export const handleReplicateTryoutDialog = async (dispatch, item, setRefetch, categoryId, parentCategoryId) => {
  try {
    Swal.fire({
      title: 'Apakah anda yakin?',
      html: `Anda akan menduplikasi Tryout <strong>${item.title}</strong> ini, <strong>${categoryId ? 'dengan kategori ID ' + categoryId : '--Tanpa Kategori'}</strong>.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await dispatch(replicateTryout({ id: item.id, isReplicateContainerOnly: 0, category_id: categoryId }));
        if (response.payload.status === 201) {
          setRefetch(prevRefetch => !prevRefetch);
          Swal.fire(
            'Berhasil!',
            'Data telah diduplikasi.',
            'success'
          );
        } else {
          Swal.fire(
            'Gagal!',
            'Data gagal diduplikasi.',
            'error'
          );
        }
      }
    });
  }
  catch (error) {
    console.error("Replicate error:", error);
  }
}


/* **************************************************** */
/* Pagination                                           */
/* **************************************************** */

export const handleBackPagination = (pagination, filters, dispatch, setFilters, setRefetch) => {
  if (pagination.current_page > 1) {
    dispatch(setFilters({
      ...filters,
      page: pagination.current_page - 1
    }));
    setRefetch(prevRefetch => !prevRefetch);
  }
}

export const handleNextPagination = (pagination, filters, dispatch, setFilters, setRefetch) => {
  if (pagination.current_page < pagination.last_page) {
    dispatch(setFilters({
      ...filters,
      page: pagination.current_page + 1
    }));
    setRefetch(prevRefetch => !prevRefetch);
  }
}

export const handleClickPagination = (page, filters, dispatch, setFilters, setRefetch) => {
  console.log(page);
  dispatch(setFilters({
    ...filters,
    page: page
  }));
  setRefetch(prevRefetch => !prevRefetch);
}