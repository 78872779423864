import { createAsyncThunk } from "@reduxjs/toolkit";
import { TentorMemberServices } from "../../../../Services/axios/TentorMember.Service";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

export const submitTentorMember = createAsyncThunk(
	"Tentors/submitTentorMember",
	async (data) => {
		try {
			const formData = new FormData();
			formData.append("tentor_id", data.tentor_id);
			formData.append("name", data.name);
			formData.append("type", data.type);
			formData.append("total_student", data.total_student);
			let response;
			if (data.isUpdate) {
				response = await TentorMemberServices.update(data.id, formData);
				toast.success("Tentor member berhasil diperbarui", {
					position: toast.POSITION.TOP_RIGHT,
				});
			} else {
				response = await TentorMemberServices.create(formData);
				toast.success("Tentor member berhasil ditambahkan", {
					position: toast.POSITION.TOP_RIGHT,
				});
			}
			data.successCallback && data.successCallback();
			return response.data.data;
		} catch (error) {
			console.log(error);
			for (const errorField in error.response.data.errors) {
				if (error.response.data.errors.hasOwnProperty(errorField)) {
					const errorMessages = error.response.data.errors[errorField];
					let errorMessage = errorMessages[0];
					toast.error(errorMessage, {
						position: toast.POSITION.TOP_RIGHT,
						autoClose: 3000,
					});
				}
			}
			if (error.code === "ERR_NETWORK") {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Permintaan gagal. Mohon periksa koneksi internet Anda.",
				});
			} else if (error.response.status === 500) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Terjadi kesalahan sistem. Mohon hubungi pengembang.",
				});
			}
		}
	}
);

export const deleteTentorMember = createAsyncThunk(
	"Tentors/deleteTentorMember",
	async (id) => {
		try {
			const response = await TentorMemberServices.delete(id);
			return response.data.data;
		} catch (error) {
			if (error.response && error.response.data) {
				toast.error(error.response.data.message, {
					position: toast.POSITION.TOP_RIGHT,
				});
			}
		}
	}
);
