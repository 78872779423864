import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Input, Collapse, Label, Button, FormGroup } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { setFilters } from '../../../../../Features/Classes/Schedule/Teaching/TeachingSlice';
import { fetchPrivateSchedule } from '../../../../Classes/Schedule/Teaching/TeachingUtils';
import FilterTentorPrivate from '../../../../Classes/Schedule/Teaching/Filter/FilterTentorTeaching';
import Select from 'react-select';

const FindPrivate = () => {
    const [isFilter, setIsFilter] = useState(true);
    const dispatch = useDispatch()
    const filters = useSelector(state => state.Teaching.filters)
    const filterTentor = useSelector((state) => state.Tentor.filters);
    const [selectedSortValue, setSelectedSortValue] = useState(null)
    const [selectedMonthValue, setSelectedMonthValue] = useState(null)
    const [selectedDate, setSelectedDate] = useState(null)

    const options = [
        { value: '-id', label: 'Terbaru' },
        { value: 'id', label: 'Terlama' },
        { value: '-duration', label: 'Durasi Terpanjang' },     
        { value: 'duration', label: 'Durasi Terpendek' },        
        { value: '-check_in_time', label: 'Check-In Terbaru' },
        { value: 'check_in_time', label: 'Check-In Terlama' },  
        { value: '-check_out_time', label: 'Check-Out Terbaru' },
        { value: 'check_out_time', label: 'Check-Out Terlama' },  
    ];

    useEffect(() => {
        const selectedSort = filters.sort ? options.find(option => option.value === filters.sort) : null;
        setSelectedSortValue(selectedSort);
    }, [filters.sort]);

    const handleSelectChangeSort = (selectedOption) => {
        if(selectedOption === null) {
            setSelectedSortValue(null);
            return;
        }
        
        setSelectedSortValue(selectedOption.value);
        const newFilters = {
            ...filters,
            'sort': selectedOption === null ? '' : selectedOption.value,
        }
        dispatch(setFilters(newFilters));
        fetchPrivateSchedule(dispatch, newFilters);
    }

    const handleSelectMonth = (value) => {
        setSelectedMonthValue(value);
        const newFilters = {
            ...filters,
            'filter[month_year]': value,
        }
        dispatch(setFilters(newFilters));
        fetchPrivateSchedule(dispatch, newFilters);
    }

    const handleSelectDate = (value) => {
        setSelectedDate(value);
        const newFilters = {
            ...filters,
            'filter[date]': value,
        }
        dispatch(setFilters(newFilters));
        fetchPrivateSchedule(dispatch, newFilters);
    }

    const handleClickSearch = (e) => {
        e.preventDefault()
        const newFilters = {
            ...filters,
            'page': 1,
        };
        dispatch(setFilters(newFilters));
        fetchPrivateSchedule(dispatch, newFilters);
    }

    const handleResetFilter = (e) => {
        e.preventDefault()
        const newFilters = {
            ...filters,
            "filter[id]": "",
            "filter[tentor_id]": "",
            "filter[duration]": "",
            "filter[total_student]": "",
            "filter[tentor.status]": "",
            "filter[date]": "",
            "filter[month_year]": "",
            "date": "",
            "month": "",
            "start_date": "",
            "end_date": "",
            "sort": "",
        }
        setSelectedSortValue(null)
        setSelectedMonthValue(null)
        setSelectedDate(null)
        dispatch(setFilters(newFilters));
        fetchPrivateSchedule(dispatch, newFilters);
    }

    return (
        <Fragment>
            <Col xl="12" style={{ zIndex: '8' }}>
                <div className="job-filter">
                    <div className="faq-form">
                        <Row>
                            <Col className="mt-3">
                                <FilterTentorPrivate />
                            </Col>
                            <Col className="mt-3">
                                <Select
                                    options={options}
                                    className="js-example-basic-single"
                                    isMulti={false}
                                    isClearable={true}
                                    placeholder="Sort by.."
                                    onChange={handleSelectChangeSort}
                                    value={selectedSortValue}
                                />
                            </Col>
                            <Col className="mt-3">
                                <FormGroup>
                                    <Input
                                        id="month"
                                        name="month"
                                        type="month"
                                        value={selectedMonthValue ?? ''}
                                        onChange={(e) => handleSelectMonth(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col className="mt-3">
                                <FormGroup>
                                    <Input
                                        id="date"
                                        name="date"
                                        type="date"
                                        value={selectedDate ?? ''}
                                        onChange={(e) => handleSelectDate(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col className="d-flex justify-content-end align-items-center">
                                <Button color="warning me-2" onClick={handleResetFilter}>Reset</Button>
                                <Button color="primary me-2" onClick={handleClickSearch}>Filter</Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Col>
        </Fragment>
    );
};

export default FindPrivate;