import React, { Fragment, useEffect, useState } from 'react';
import { PlusCircle } from 'react-feather';
import { Card, Col, Row, CardBody, CardHeader, CardFooter, Table } from 'reactstrap';
import { H1, H2, H3, H4, H5, H6, Image, Btn, LI, P, UL, Spinner, Badges } from '../../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setDetail, setFilters } from '../../../Features/Classes/Class/ClassSlice';
import { setField, resetState } from '../../../Features/Classes/Class/addClassSlice';
import { setFilters as setFilterSchedule } from '../../../Features/Classes/Schedule/ScheduleSlice';
import { resetDetailMemberClass } from '../../../Features/Classes/Class/detailMemberClassSlice';
import {
    fetchClass,
    handleDeleteClass,
    handleEditClass,
    handleGetDetailClass,
    handleNextPagination,
    handleBackPagination,
    handleClickPagination,
    handleReplicateClass
} from '../../../Component/Classes/Class/ClassUtils';
import PaginationComponent from '../../Learning/Common/Pagination';
import RawHTMLComponent from '../../../Component/Learning/Common/RawHTML';
import Moment from 'moment';
import 'moment/locale/id';


const ClassListTable = () => {
    const history = useNavigate()
    const dispatch = useDispatch()
    const filters = useSelector(state => state.Class.filters)
    const filterUsers = useSelector(state => state.userFiltered.filters);
    const filterSchedule = useSelector(state => state.Schedule.filters)
    const isLoading = useSelector(state => state.Class.isLoading)
    const cards = useSelector(state => state.Class.data.data)
    const pagination = useSelector(state => state.Class.data.pagination)
    const [refatch, setRefatch] = useState(false)
    // console.log('cards', cards);

    useEffect(() => {
        const filterDefault = {
            ...filterSchedule,
            "page": 1,
        }
        dispatch(setFilterSchedule(filterDefault))
        fetchClass(dispatch, filters, refatch)
        dispatch(resetDetailMemberClass())
    }, [refatch])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <Fragment>
            {isLoading ? (
                <Col lg="12">
                    <div className="loader-box">
                        <Spinner attrSpinner={{ className: 'loader-30' }} />
                    </div>
                    <H6 attrH6={{ className: 'sub-title mb-0 text-center' }}>{'Data Loading...'}</H6>
                </Col>
            ) : (
                 cards && cards.length > 0 && (
                    <Col sm="12 order-2">
                        <Card>
                            <CardBody>
                                <Table className="table-bordered table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="bg-primary text-light text-center" style={{ verticalAlign: 'middle', width: '5%' }}>#</th>
                                            <th scope="col" className="bg-primary text-light" style={{verticalAlign: 'middle' }}>Nama Kelas</th>
                                            <th scope="col" className="bg-primary text-light" style={{ verticalAlign: 'middle' }}>Deskripsi</th>
                                            <th scope="col" className="bg-primary text-light text-center" style={{ width: '50px', verticalAlign: 'middle' }}>Jumlah Siswa</th>
                                            <th scope="col" className="bg-primary text-light text-center" style={{ width: '160px', verticalAlign: 'middle' }}>Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            cards && cards.map((item, index) =>
                                                <tr key={`class-${index}`} className="table-light">
                                                    <th>{pagination.from + index}</th>
                                                    <td>{item.name}</td>
                                                    <td>
                                                        <RawHTMLComponent htmlContent={item.description} maxCharacters={45} />
                                                    </td>
                                                    <td className='text-center'>
                                                        <Badges attrBadge={{ color: 'warning' }} > {item.members.length || 0} </Badges>
                                                    </td>
                                                    <td className='text-center'>
                                                        <div>
                                                            <Btn
                                                                attrBtn={{
                                                                    title: 'Duplicate Class',
                                                                    color: 'link',
                                                                    outline: true,
                                                                    className: 'p-1',
                                                                    onClick: () => handleReplicateClass(dispatch, item, setRefatch)
                                                                }}
                                                            >
                                                                <i className="fa fa-copy mx-auto fa-lg"></i>
                                                            </Btn>
                                                            <Link to={`${process.env.PUBLIC_URL}/classes/class-user-detail/${item.id}`} title="Detail Siswa Kelas" className="p-1" 
                                                            onClick={() => {
                                                                handleGetDetailClass(dispatch, item, setDetail);
                                                                }}
                                                            >
                                                                <i className="icofont icofont-users-social fa-lg"></i>
                                                            </Link>
                                                            <Link to={`${process.env.PUBLIC_URL}/classes/class-schedule-detail/${item.id}`} title="Detail Sesi Kelas" className="p-1" 
                                                            onClick={() => handleGetDetailClass(dispatch, item, setDetail)}
                                                            >
                                                                <i className="fa fa-info-circle mx-auto fa-lg"></i>
                                                            </Link>
                                                            <Link to={`${process.env.PUBLIC_URL}/classes/class-add/${item.id}`} title="Edit Kelas" className="p-1" 
                                                            onClick={() => handleEditClass(dispatch, item, setField, setDetail)}
                                                            >
                                                                <i className="fa fa-edit mx-auto text-secondary fa-lg"></i>
                                                            </Link>
                                                            <Btn
                                                                attrBtn={{
                                                                    title: 'Delete',
                                                                    color: 'link',
                                                                    outline: true,
                                                                    className: 'p-1',
                                                                    onClick: () => handleDeleteClass(dispatch, item.id, setRefatch)
                                                                }}
                                                            >
                                                                <i className="fa fa-trash-o mx-auto text-danger fa-lg"></i>
                                                            </Btn>
                                                        </div >
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </CardBody>
                            <CardFooter>
                                {pagination && (
                                <PaginationComponent
                                    pagination={pagination}
                                    handleBackPagination={() => handleBackPagination(pagination, filters, dispatch, setFilters, setRefatch)}
                                    handleClickPagination={(page) => handleClickPagination(page, filters, dispatch, setFilters, setRefatch)}
                                    handleNextPagination={() => handleNextPagination(pagination, filters, dispatch, setFilters, setRefatch)}
                                />
                                )}
                            </CardFooter>
                        </Card>
                    </Col>
                )
            )}

            {isLoading === false && !cards ? (
                <Col lg="12">
                    <H3 attrH3={{ className: 'sub-title mb-5 text-center' }}>Sumber Data Tidak Tersedia</H3>
                </Col>
            ) : (
                isLoading === false && cards.length === 0 && (
                    <Col lg="12">
                        <H3 attrH3={{ className: 'sub-title mb-5 text-center' }}>Data Tidak Ditemukan</H3>
                    </Col>
                )
            )}
        </Fragment>
    );
};

export default ClassListTable;