import { createSlice } from "@reduxjs/toolkit";
import { submitTentor } from "./Utils/TentorUtils";

const initialState = {
	name: "",
	phone: "",
	mastered_fields: "",
	photo: null,
	photo_url: "",
	status: "active",
	email: "",
	password: "",
	isLoading: false,
	isUpdate: false,
	id: "",
};

const addTentorSlice = createSlice({
	name: "addTentor",
	initialState: initialState,
	reducers: {
		setField: (state, action) => {
			const { field, value } = action.payload;
			state[field] = value;
		},
		resetState: (state) => {
			return initialState;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(submitTentor.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(submitTentor.fulfilled, (state) => {
				state.isLoading = false;
			})
			.addCase(submitTentor.rejected, (state, action) => {
				state.isLoading = false;
				console.log(action.error.message, "error action");
			});
	},
});

export const { setField, resetState } = addTentorSlice.actions;

export default addTentorSlice.reducer;
