import { fetchClasses, deleteClasses, replicateClass } from '../../../Features/Classes/Class/Utils/ClassUtils';
import Swal from 'sweetalert2';

/* **************************************************** */
/* CRUD Class                                       */
/* **************************************************** */

export const fetchClass = (dispatch, filters, refetch) => {
    dispatch(fetchClasses(filters));
}

export const handleDeleteClass = async (dispatch, id, setRefetch) => {
    try {
        Swal.fire({
            title: 'Apakah anda yakin?',
            text: "Anda tidak dapat mengembalikan data yang telah dihapus!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await dispatch(deleteClasses(id));
                setRefetch(prevRefetch => !prevRefetch);
                Swal.fire(
                    'Terhapus!',
                    'Data telah dihapus.',
                    'success'
                )
            }
        })
    } catch (error) {
        console.error("Delete error:", error);
    }
}

export const handleEditClass = async (dispatch, item, setField) => {
    console.log(item, "handleEditClass");
    dispatch(setField({ field: 'id', value: item.id }));
    dispatch(setField({ field: 'isUpdate', value: true }));
    const fieldsToUpdate = ['name', 'description'];
    fieldsToUpdate.forEach(field => {
        dispatch(setField({ field, value: item[field] }));
    });
    dispatch(setField({ field: 'bannerLink', value: item.banner }));
}

export const handleGetDetailClass = async (dispatch, item, setDetail) => {
    console.log(item, "handleGetDetailClass");
    dispatch(setDetail(item));
}

export const handleReplicateClass = async (dispatch, item, setRefetch) => {
    try {
        Swal.fire({
            title: 'Apakah anda yakin?',
            html: `Anda akan menduplikasi Kelas <strong>${item.name}</strong> ini!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const response = await dispatch(replicateClass(item.id));
                if (response.payload.status === 201) {
                    setRefetch(prevRefetch => !prevRefetch);
                    Swal.fire(
                        'Berhasil!',
                        'Data telah diduplikasi.',
                        'success'
                    )
                } else {
                    Swal.fire(
                        'Gagal!',
                        'Data gagal diduplikasi.',
                        'error'
                    )
                }
            }
        })
    } catch (error) {
        console.error("Delete error:", error);
    }
}
/* **************************************************** */
/* Pagination                                           */
/* **************************************************** */

export const handleBackPagination = (pagination, filters, dispatch, setFilters, setRefetch) => {
  if (pagination.current_page > 1) {
    dispatch(setFilters({
      ...filters,
      page: pagination.current_page - 1
    }));
    setRefetch(prevRefetch => !prevRefetch);
  }
}

export const handleNextPagination = (pagination, filters, dispatch, setFilters, setRefetch) => {
  if (pagination.current_page < pagination.last_page) {
    dispatch(setFilters({
      ...filters,
      page: pagination.current_page + 1
    }));
    setRefetch(prevRefetch => !prevRefetch);
  }
}

export const handleClickPagination = (page, filters, dispatch, setFilters, setRefetch) => {
  console.log(page);
  dispatch(setFilters({
    ...filters,
    page: page
  }));
  setRefetch(prevRefetch => !prevRefetch);
}