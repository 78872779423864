import React, { Fragment, useState } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import { Post, Discard } from '../../../Constant';
import { Btn, H5, H6, Image, Spinner } from '../../../AbstractElements';
import FormPost from './SubMateriForm';
import DropzoneCommon from '../../../Component/Learning/Common/Upload/Dropzone';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { submitSubMaterial } from '../../../Features/Learning/SubMateri/Utils/SubMateriUtils';
import { resetState, setField } from '../../../Features/Learning/SubMateri/addSubMaterialSlice';
import Swal from 'sweetalert2';

const BlogPostContain = () => {
  const dispatch = useDispatch();
  const data = useSelector(state => state.addSubMaterial)
  const startUpload = useSelector(state => state.addSubMaterial.isLoading);
  const isUpdate = useSelector(state => state.addSubMaterial.isUpdate);

  const history = useNavigate();
  const handleSuccess = () => {
    dispatch(resetState());
    history(`${process.env.PUBLIC_URL}/learning/sub-materi-list`);
  };

  const handleChangeStatus = ({ meta, file }, status) => {
    if (status === 'removed') {
      dispatch(setField({ field: 'file', value: null }));
    } else if (status === 'done') {
      dispatch(setField({ field: 'file', value: file }));

      if(data.type === 'video'){
        dispatch(setField({ field: 'duration', value: Math.floor(meta.duration) }));
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let errorMessage = '';
    if (!isUpdate) {
      if (data.name === '' || data.description === '' || data.description === '<p><br></p>' || data.type === '') {
        if(data.name === '') {
          errorMessage = 'Judul tidak boleh kosong.';
        } else if (data.type === '') {
          errorMessage = 'Tipe belum dipilih.';
        } else if (data.description === '' || data.description === '<p><br></p>') {
          errorMessage = 'Deskripsi tidak boleh kosong.';
        } 
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: errorMessage,
        })
      } else if (data.type !== '' && data.file === null && data.link === '') {
        if (data.type === 'ebook' || data.type === 'youtube') {
          if (data.link === '') {
            errorMessage = 'Link tidak boleh kosong.';
          }
        } else if (data.type === 'image' || data.type === 'video' || data.type === 'audio') {
          if (data.file === null) {
            errorMessage = 'File tidak boleh kosong.';
          }
        }
        Swal.fire({
          icon: 'error',
          title: 'Oops...tipe',
          text: errorMessage,
        })
      } else {
        // console.log(data,'sukses data')
        dispatch(submitSubMaterial({ ...data, successCallback: handleSuccess }))
      }
    } else {
      if (data.name === '' || data.description === '' || data.description === '<p><br></p>' || data.type === '') {
        if(data.name === '') {
          errorMessage = 'Judul tidak boleh kosong.';
        } else if (data.type === '') {
          errorMessage = 'Tipe belum dipilih.';
        } else if (data.description === '' || data.description === '<p><br></p>') {
          errorMessage = 'Deskripsi tidak boleh kosong.';
        } 
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: errorMessage,
        })
      } else {
        // console.log(data,'sukses data')
        dispatch(submitSubMaterial({ ...data, successCallback: handleSuccess }))
      }
    }
  };

  const handleDiscard = (e) => {
    e.preventDefault();
    dispatch(resetState());
    history(`${process.env.PUBLIC_URL}/learning/sub-materi-list`);
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            {startUpload ? (
                <Col lg="12">
                    <div className="loader-box">
                        <Spinner attrSpinner={{ className: 'loader-30' }} />
                    </div>
                    <H6 attrH6={{ className: 'sub-title mb-0 text-center' }}>{'Sedang proses unggah...'}</H6>
                </Col>
            ) : (
            <Card>
              <CardHeader className='pb-0'><H5>{isUpdate ? '' : 'Submateri Baru'}</H5></CardHeader>
              <CardBody className="add-post">
                <FormPost />
                {(data.type === 'image' || data.type === 'video' || data.type == 'audio') && (
                      <DropzoneCommon label={'File Sub Materi'} inputContent={'Silahkan upload file.'} handleChangeStatus={handleChangeStatus} accept={data.type === 'image' ? 'image/*' : data.type === 'video' ? 'video/*' : data.type === 'audio' ? 'audio/*' : ''} />
                )}
                <div className="btn-showcase text-end">
                  <Btn attrBtn={{ color: 'primary', type: 'submit', onClick: handleSubmit }}>{Post}</Btn>
                  <Btn attrBtn={{ color: 'light', type: 'reset', onClick: handleDiscard }}>{Discard}</Btn>
                </div>
              </CardBody>
            </Card>
            )}
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default BlogPostContain;