import { createAsyncThunk } from "@reduxjs/toolkit";
import { VoucherServices } from "../../../../Services/axios/Voucher.Service";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

export const fetchVouchers = createAsyncThunk(
	"Vouchers/fetchVouchers",
	async (filters) => {
		try {
			const response = await VoucherServices.getAll(filters);
			return response.data;
		} catch (error) {
			if (error.response) {
				const statusCode = error.response.status;
				let errorMessage = "Terjadi kesalahan saat mengambil data.";

				if (statusCode === 400) {
					errorMessage = "Permintaan tidak valid.";
				} else if (statusCode === 401) {
					errorMessage =
						"Anda tidak diotorisasi untuk mengakses sumber daya ini.";
				} else if (statusCode === 404) {
					errorMessage = "Sumber daya tidak ditemukan.";
				} else if (statusCode === 500) {
					errorMessage = "Terjadi kesalahan internal server.";
				} else if (statusCode === 0) {
					errorMessage = "Tidak ada koneksi internet.";
				}

				Swal.fire({
					icon: "error",
					title: statusCode === 0 ? "Error" : `Error ${statusCode}`,
					text: errorMessage,
				});
			} else if (error.request) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Permintaan gagal. Mohon periksa koneksi internet Anda.",
				});
			} else {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Terjadi kesalahan. Mohon coba lagi nanti.",
				});
			}
			throw error;
		}
	}
);

export const submitVoucher = createAsyncThunk(
	"addVoucher/submitVoucher",
	async (data) => {
		try {
			const formData = new FormData();
			formData.append("code", data.code);
			formData.append("is_percentage", data.is_percentage);
			formData.append("discount", data.discount);
			formData.append("valid_until", data.valid_until);
			formData.append("minimum_nominal", data.minimum_nominal);
			data.membership.forEach((membership, index) => {
				formData.append(`membership[${index}]`, membership.value);
			});
			let response;
			if (data.isUpdate) {
				response = await VoucherServices.update(data.id, formData);
			} else {
				response = await VoucherServices.create(formData);
			}
			Swal.fire({
				icon: "success",
				title: "Sukses",
				text: data.isUpdate
					? "Voucher berhasil diubah."
					: "Voucher berhasil ditambahkan.",
			});
			data.successCallback && data.successCallback();
			return response.data.data;
		} catch (error) {
			for (const errorField in error.response.data.errors) {
				if (error.response.data.errors.hasOwnProperty(errorField)) {
					const errorMessages = error.response.data.errors[errorField];
					let errorMessage = errorMessages[0];
					toast.error(errorMessage, {
						position: toast.POSITION.TOP_RIGHT,
					});
				}
			}
			if (error.code === "ERR_NETWORK") {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Permintaan gagal. Mohon periksa koneksi internet Anda.",
				});
			} else if (error.response.status === 500) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Terjadi kesalahan sistem. Mohon hubungi pengembang.",
				});
			}
			throw error;
		}
	}
);

export const deleteVoucher = createAsyncThunk(
	"Vouchers/deleteVoucher",
	async (id) => {
		try {
			const response = await VoucherServices.delete(id);
			return response.data.data;
		} catch (error) {
			if (error.response && error.response.data) {
				toast.error(error.response.data.message, {
					position: toast.POSITION.TOP_RIGHT,
				});
				console.log(error.response.data);
			}
		}
	}
);

export const replicateVoucher = createAsyncThunk(
	"Vouchers/replicateVoucher",
	async (id) => {
		try {
			const response = await VoucherServices.replicate(id);
			return response;
		} catch (error) {
			if (error.response && error.response.data) {
				toast.error(error.response.data.message, {
					position: toast.POSITION.TOP_RIGHT,
				});
			}
		}
	}
);