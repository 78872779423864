import React, { Fragment, useEffect, useState } from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Row, Form, FormGroup, Label, Input, Table } from 'reactstrap';
import { H3, H4, H5, Image, Btn } from '../../../AbstractElements';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import VideoPlayer from './VideoPlayer';
import AudioPlayer from './AudioPlayer';
import RawHTMLComponent from '../../../Component/Learning/Common/RawHTML';
import AttachModal from '../../../Component/Learning/Common/Modal/ModalButton';
import AsyncSelectCommon from '../../../Component/Learning/Common/SelectOption/AsyncSelect';
import { fetchTryouts } from '../../../Component/Learning/Tryout/TryoutUtils';
import { handleDetach } from '../../../Component/Learning/SubMateri/SubMateriUtils';
import { attachDetachSubMaterial, fetchSubMaterial } from '../../../Features/Learning/SubMateri/Utils/SubMateriUtils';
import { setField, resetState } from '../../../Features/Learning/SubMateri/attachDetachSubMaterialSlice';
import { setDetail } from '../../../Features/Learning/SubMateri/subMaterialSlice';
import Moment from 'moment';
import Swal from 'sweetalert2';
import CleanLimitHTML from '../../../Component/Learning/Common/GenerateContent/CleanLimitHtml';

const SubMateriDetails = () => {
  const dispatch = useDispatch();
  const detail = useSelector(state => state.subMaterial.detail);
  const filter = useSelector(state => state.subMaterial.filters);
  const data = useSelector(state => state.attachDetachSubMaterial);
  const [modal, setModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState([]);
  const dataOptions = useSelector(state => state.Tryout.data);
  const filtersTryout = useSelector(state => state.Tryout.filters);
  const newFiltersTryout = {
    ...filtersTryout,
    'filter[is_quiz]': 1,
  };

  const toggle = () => {
    setModal(!modal)
    // dispatch(resetState());
  };

  useEffect(() => {
    fetchTryouts(dispatch, newFiltersTryout);
    window.scrollTo(0, 0);
  }, [detail.id]);

  const promiseOptions = (inputValue, callback) => {
    const filteredOptions = filteredData(inputValue);
    callback(filteredOptions);
    fetchTryouts(dispatch, ({
      ...newFiltersTryout,
      'filter[title]': inputValue,
    }));
  };

  const filteredData = (inputValue) => {
    return dataOptions.data.map((item) => ({
      value: item.id, // Sesuaikan dengan properti value dalam dataOptions Anda
      label: item.title, // Sesuaikan dengan properti label dalam dataOptions Anda
    })).filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedValue(selectedOption);
    // console.log(selectedOption, 'selectedOption')
    if (selectedOption === null) {
      setSelectedValue([]);
      dispatch(setField({ field: 'idTryout', value: '' }));
    } else {
      dispatch(setField({ field: 'idTryout', value: selectedOption.value }));
      dispatch(setField({ field: 'method', value: 'attach' }));
      dispatch(setField({ field: 'idSubmateri', value: detail.id }));
    }
  };

  const handleChanges = (e) => {
    const { name, value } = e.target;
    dispatch(setField({ field: name, value: value }));
  };

  const handleSubmit = async () => {
    // console.log(data, 'data')
    if (data.idTryout === '' || data.type === '') {
      if (data.type === '') {
        Swal.fire(
          'Gagal!',
          'Silahkan pilih tipe kuis.', 
          'error'
        )
      } else if (data.idTryout === '') {
        Swal.fire(
          'Gagal!',
          'Silahkan pilih kuis.',
          'error'
        )
      }
    } else {
      try {
        const response = await dispatch(attachDetachSubMaterial(data));
        if (response.payload === true) {
          setModal(!modal);
          setSelectedValue([]);
          dispatch(resetState());
          const getSubMateri = await dispatch(fetchSubMaterial({
            ...filter,
            'filter[id]': detail.id,
            "page": 1,
          }));
          dispatch(setDetail(getSubMateri.payload.data[0]));
          Swal.fire(
            'Berhasil!',
            'Tambah Kuis ke Submateri berhasil.',
            'success'
          )
        }
      } catch (error) {
        // Tanggapan error
        console.error('Error:', error);
        // Modal tidak akan ditutup jika terjadi kesalahan
      }
    }
  };


  return (
    <Fragment>
      <div className="blog-box blog-details">
        <Card>
          <CardHeader>
            <div className="pro-group pt-0 border-0">
              <Row>
                <Col>
                    <H3 attrH3={{ className: 'd-inline-block' }}>{detail.name}</H3>
                </Col>
                <Col>
                  <H3 attrH3={{ className: 'd-inline-block' }}>Tipe: {detail.type === 'image' ? 'Gambar' : detail.type === 'video' ? 'Video' : detail.type === 'audio' ? 'Audio' : detail.type === 'ebook' ? 'E-book' : detail.type === 'youtube' ? 'Youtube' : ''}</H3>
                </Col>
              </Row>
            </div>
          </CardHeader>
          <CardBody>
            <div className="pro-group pt-0">
              <div className="product-price mb-3"> 
                <H4 attrH4={{ className: 'd-inline-block' }}>{'Deskripsi Submateri : '}</H4>
                <RawHTMLComponent htmlContent={detail.description} />
              </div>
            </div>
            <div className="pro-group pt-0">
              <div className="product-price mb-3">
                <H4 attrH4={{ className: 'd-inline-block' }}>{'Konten Submateri : '}</H4>
              </div>
            </div>
            {(detail.type == 'video') && (
              <VideoPlayer link={detail.link} width={720} />
            )}
            {(detail.type == 'audio') && (
              <AudioPlayer link={detail.link} />
            )}
            {detail.type === 'image' && (
              <Image attrImage={{ className: 'img-fluid', src: detail.link, width: '500px', alt: detail.name }} />
            )}
            {detail.type === 'ebook' && (
              <Link to={detail.link} target="_blank">
                <Button color="primary" className="btn-block">Buka</Button>
              </Link>
            )}
            {detail.type === 'youtube' && (
              <iframe width="560" height="315" src={detail.link} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
            )}
          </CardBody>
          <CardFooter>
            <Row>
              <Col>
                <b>Created At : </b>{Moment(detail.created_at).format('dddd, DD/MM/yyyy')} - {Moment(detail.created_at).format('HH:mm:ss')} WIB
              </Col>
              <Col className='text-end'>
                <Link to={`${process.env.PUBLIC_URL}/learning/sub-materi-list`}>
                  <Btn attrBtn={{ color: 'warning', className: 'btn btn-primary m-r-10' }}>
                    <i className="fa fa-chevron-circle-left fa-lg me-2"></i>Back
                  </Btn>
                </Link>
                <AttachModal colorBtn={'primary'} item={detail} buttonLabel={'Tambahkan Kuis'} toggle={toggle} modalLabel={'Tambah Kuis ke Submateri'} modal={modal} handleSubmit={handleSubmit} >
                  <Form>
                    <FormGroup className='mb-3'>
                      <Label for="validationImage" style={{ fontWeight: 'bold' }}>Tipe Kuis</Label>
                      <div className="m-checkbox-inline">
                        <Label for="pretest">
                          <Input className="radio_animated" id="pretest" type="radio" name="type" value="pretest"
                            checked={data.type === 'pretest'}
                            onChange={handleChanges} />Pre-Test
                        </Label>
                        <Label for="posttest">
                          <Input className="radio_animated" id="posttest" type="radio" name="type" value="posttest"
                            checked={data.type === 'posttest'}
                            onChange={handleChanges} />Post-Test
                        </Label>
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <AsyncSelectCommon label={'Kuis'} placeholder={'Silahkan pilih kuis...'}
                        isMulti={false}
                        promiseOptions={promiseOptions}
                        handleSelectChange={handleSelectChange}
                        selectedValue={selectedValue}
                      />
                    </FormGroup>   
                  </Form>
                </AttachModal>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </div>
      {detail.tryout && detail.tryout.length > 0 && (
        <Col sm="12 order-2">
          <Card>
            <CardHeader>
              <H5>Daftar Kuis Submateri {detail.title}</H5>
            </CardHeader>
            <div className="table-responsive">
              <Table>
                <thead className="table-light">
                  <tr>
                    <th scope="col">{'#'}</th>
                    <th scope="col">{'Judul Kuis'}</th> 
                    <th scope="col">{'Tipe'}</th>
                    <th scope="col">{'Deskripsi'}</th>
                    <th scope="col">Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    detail.tryout && detail.tryout.map((item, index) =>
                      <tr key={`tryout-${index}`}>
                        <th>{index + 1}</th>
                        <td>{item.title}</td>
                        <td>{item.type === 'pretest' ? 'Pre-Test' : item.type === 'posttest' ? 'Post-Test' : ''}</td>
                        <td><CleanLimitHTML htmlContent={item.description} limit={40} /></td>
                        <td>
                          <Btn
                            attrBtn={{
                              color: 'link',
                              outline: true,
                              className: 'p-1',
                              onClick: () => {
                                handleDetach(detail, setDetail, item, setField, filter, data, dispatch, attachDetachSubMaterial, resetState)
                              }
                            }}
                          >
                            <i className="fa fa-trash-o mx-auto text-danger fa-lg"></i>
                          </Btn>
                        </td>
                      </tr>
                    )
                  }
                </tbody>
              </Table>
            </div>
          </Card>
        </Col>
      )}
    </Fragment>
  );
};

export default SubMateriDetails;