import React, { Fragment, useContext, useEffect, useState } from "react";
import { ChevronDown, ChevronUp, HelpCircle } from "react-feather";
import {
	Row,
	Col,
	Card,
	CardBody,
	CardHeader,
	Collapse,
	Media,
} from "reactstrap";
import { Btn, H4, H5, H6, P, Image, Spinner } from "../../../AbstractElements";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setFilters } from "../../../Features/Learning/Categories/categoriesSlice";
import { setField } from "../../../Features/Learning/Categories/addCategoriesSlice";
import PaginationComponent from "../Common/Pagination";
import {
	fetchCategory,
	handleEditCategories,
	handleDeleteCategories,
	handleBackPagination,
	handleNextPagination,
	handleClickPagination,
} from "./CategoriesUtils";

const CategoriesBox = () => {
	const [isActivity, setIsActivity] = useState([]);
	const [isChild, setIsChild] = useState([]);
	const para =
		"Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.";

	const dispatch = useDispatch();
	const filters = useSelector((state) => state.Categories.filters);
	const isLoading = useSelector((state) => state.Categories.isLoading);
	const cards = useSelector((state) => state.Categories.data.data);
	const pagination = useSelector((state) => state.Categories.data.pagination);
	const [refatch, setRefatch] = useState(false);

	const handleChange = (e) => {
		let collapseCopy = [...isActivity];
		collapseCopy[e] = !collapseCopy[e];
		setIsActivity(collapseCopy);
	};

	useEffect(() => {
		fetchCategory(dispatch, filters, refatch);
	}, [refatch]);

	const cardHeader = (item) => {
		return (
			<CardHeader style={{ marginRight: "15px", padding: "8px" }}>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
					}}>
					<div style={{ display: "flex", alignItems: "center" }}>
						<Image
							attrImage={{
								className: "rounded-circle img-40",
								src: `${item.icon}`,
								alt: "",
							}}
						/>
						<div
							style={{
								marginLeft: "10px",
								display: "flex",
								alignItems: "center",
							}}>
							<H6 attrH6={{ className: "m-0" }}>{item.id}</H6> &nbsp; - &nbsp;
							<H6 attrH6={{ className: "m-0" }}>{item.title}</H6>
						</div>
					</div>
					<div style={{ display: "flex", alignItems: "center" }}>
						{item.is_parent === 1 && item.children.length > 0 && (
							<Btn
								attrBtn={{
									color: "ps-0",
									onClick: () => handleChange(item.id),
									datatoggle: "collapse",
									datatarget: `#collapseicon${item.id}`,
									ariaexpanded: "true",
									ariacontrols: `collapseicon${item.id}`,
									style: {
										textAlign: "center",
										alignItems: "center",
										justifyContent: "center",
										display: "flex",
									},
								}}>
								<ChevronDown />
							</Btn>
						)}
						<Link
							to={`/learning/category-add/${item.id}`}
							onClick={() => handleEditCategories(dispatch, item, setField)}>
							<Btn
								attrBtn={{
									color: "link",
									outline: true,
									className: "p-1",
								}}>
								<i className="fa fa-pencil mx-auto"></i>
							</Btn>
						</Link>
						<Btn
							attrBtn={{
								color: "link",
								outline: true,
								className: "p-1",
								onClick: () =>
									handleDeleteCategories(dispatch, item.id, setRefatch),
							}}>
							<i className="fa fa-trash-o mx-auto text-danger"></i>
						</Btn>
					</div>
				</div>
			</CardHeader>
		);
	};

	return (
		<Fragment>
			{isLoading ? (
				<Col lg="12">
					<div className="loader-box">
						<Spinner attrSpinner={{ className: "loader-30" }} />
					</div>
					<H6 attrH6={{ className: "sub-title mb-0 text-center" }}>
						{"Data Loading..."}
					</H6>
				</Col>
			) : (
				<Col lg="12">
					<Row
						className="row default-according style-1 faq-accordion"
						id="accordionoc">
						<Col xl="12" lg="6">
							{cards &&
								cards.map((item) => {
									if (item.is_parent === 1) {
										return (
											<Card key={`category-${item.id}`}>
												{cardHeader(item)}
												<Collapse isOpen={isActivity[item.id]}>
													<CardBody>
														{item.children &&
															item.children.map((child) => {
																return (
																	<React.Fragment key={child.id}>
																		{cardHeader(child)}
																	</React.Fragment>
																);
															})}
													</CardBody>
												</Collapse>
											</Card>
										);
									}
								})}
						</Col>
					</Row>
				</Col>
			)}
			{!cards ||
				(cards.length === 0 && (
					<Col lg="12">
						<H4 attrH4={{ className: "sub-title mb-5 text-center" }}>
							{!cards ? "Sumber Data Tidak Tersedia" : "Data Tidak Ditemukan"}
						</H4>
					</Col>
				))}

			{isLoading === false && cards && cards.length > 0 && pagination && (
				<CardBody>
					<PaginationComponent
						pagination={pagination}
						handleBackPagination={() =>
							handleBackPagination(
								pagination,
								filters,
								dispatch,
								setFilters,
								setRefatch
							)
						}
						handleClickPagination={(page) =>
							handleClickPagination(
								page,
								filters,
								dispatch,
								setFilters,
								setRefatch
							)
						}
						handleNextPagination={() =>
							handleNextPagination(
								pagination,
								filters,
								dispatch,
								setFilters,
								setRefatch
							)
						}
					/>
				</CardBody>
			)}
		</Fragment>
	);
};
export default CategoriesBox;
