import React, { Fragment, useEffect, useState } from 'react';
import { Card, Col, CardBody, CardFooter, Table } from 'reactstrap';
import { H4, H6, Btn, Spinner, Badges } from '../../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setDetail, setFilters } from 'Features/Learning/Memberships/membershipSlice';
import { setField } from 'Features/Learning/Memberships/addMembershipSlice';
import {
    fetchMembership,
    handleDeleteMembership,
    handleEditMembership,
    handleGetDetailMembership,
    handleNextPagination,
    handleBackPagination,
    handleClickPagination,
    handleReplicateMembership
} from 'Component/Learning/Membership/MembershipsUtils';
import PaginationComponent from 'Component/Learning/Common/Pagination';
import CurrencyFormat from 'Component/Learning/Common/GenerateContent/CurrencyFormatter';
import { setFilterUsers } from 'Features/Learning/Users/userFilteredSlice';
import ThreeDotMenu from 'Component/Learning/Common/ThreeDotMenu';

const MembershipBox = () => {
    const history = useNavigate()
    const dispatch = useDispatch()
    const filters = useSelector(state => state.Membership.filters)
    const filterUsers = useSelector(state => state.userFiltered.filters);
    const isLoading = useSelector(state => state.Membership.isLoading)
    const cards = useSelector(state => state.Membership.data.data)
    const pagination = useSelector(state => state.Membership.data.pagination)
    const [refatch, setRefatch] = useState(false)
    // console.log('cards', cards);

    useEffect(() => {
        fetchMembership(dispatch, filters, refatch)
    }, [refatch])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <Fragment>
            {isLoading ? (
                <Col lg="12">
                    <div className="loader-box">
                        <Spinner attrSpinner={{ className: 'loader-30' }} />
                    </div>
                    <H6 attrH6={{ className: 'sub-title mb-0 text-center' }}>{'Data Loading...'}</H6>
                </Col>
            ) : (
                cards && cards.length > 0 ? (
                    <Col sm="12 order-2">
                        <Card>
                            <CardBody>
                                <Table className="table-bordered table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="bg-primary text-light">{'#'}</th>
                                            <th scope="col" className="bg-primary text-light">{'ID'}</th>
                                            <th scope="col" className="bg-primary text-light">{'Nama Membership'}</th>
                                            <th scope="col" className="bg-primary text-light">{'Harga & Durasi'}</th>
                                            <th scope="col" className="bg-primary text-light text-center" style={{ width: '100px' }}>{'Status'}</th> 
                                            <th scope="col" className="bg-primary text-light text-center" style={{ width: '160px' }}>{'Action'}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            cards && cards.map((item, index) =>
                                                <tr key={`course-${index}`} className="table-light">
                                                    <th>{pagination.from + index}</th>
                                                    <td>{item.id}</td>
                                                    <td>{item.title}</td>
                                                    <td>
                                                        <div>{<CurrencyFormat amount={item.price} />}</div>
                                                        <div>
                                                            <strong>
                                                                {item.duration_days} hari
                                                            </strong>
                                                        </div>
                                                    </td>
                                                    <td className='text-center'>
                                                        <Badges attrBadge={{ color: item.is_online === true ? 'primary' : 'danger' }} > {item.is_online === true ? 'Online' : 'Offline'} </Badges>
                                                        {item.is_to_only === true ? <Badges attrBadge={{ color: 'warning' }} > Tryout only </Badges> : ''}
                                                    </td>
                                                    <td className='text-center'>
                                                        <div>
                                                            <Link to={`${process.env.PUBLIC_URL}/membership/membership-users/${item.id}`} title="Users Membership" className="p-1" 
                                                            onClick={() => {
                                                                handleGetDetailMembership(dispatch, item, setDetail);
                                                                dispatch(setFilterUsers({
                                                                    ...filterUsers,
                                                                    'filter[membership.id]': item.id,
                                                                    'page': 1,
                                                                }));
                                                                }}
                                                            >
                                                                <i className="icofont icofont-users-social fa-lg"></i>
                                                            </Link>
                                                            <Btn
                                                                attrBtn={{
                                                                    title: 'Duplicate Membership',
                                                                    color: 'link',
                                                                    outline: true,
                                                                    className: 'p-1',
                                                                    onClick: () => handleReplicateMembership(dispatch, item, setRefatch)
                                                                }}
                                                            >
                                                                <i className="fa fa-copy mx-auto fa-lg"></i>
                                                            </Btn>
                                                            <Link to={`${process.env.PUBLIC_URL}/membership/membership-detail/${item.id}`} title="Detail Membership" className="p-1" onClick={() => handleGetDetailMembership(dispatch, item, setDetail)}>
                                                                <i className="fa fa-info-circle mx-auto fa-lg"></i>
                                                            </Link>
                                                            <Link to={`${process.env.PUBLIC_URL}/membership/membership-add/${item.id}`} title="Edit" className="p-1" onClick={() => handleEditMembership(dispatch, item, setField, setDetail)}>
                                                                <i className="fa fa-edit mx-auto text-secondary fa-lg"></i>
                                                            </Link>
                                                            <Btn
                                                                attrBtn={{
                                                                    title: 'Delete',
                                                                    color: 'link',
                                                                    outline: true,
                                                                    className: 'p-1',
                                                                    onClick: () => handleDeleteMembership(dispatch, item.id, setRefatch)
                                                                }}
                                                            >
                                                                <i className="fa fa-trash-o mx-auto text-danger fa-lg"></i>
                                                            </Btn>
                                                        </div >
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </CardBody>
                            <CardFooter>
                                {pagination && (
                                <PaginationComponent
                                    pagination={pagination}
                                    handleBackPagination={() => handleBackPagination(pagination, filters, dispatch, setFilters, setRefatch)}
                                    handleClickPagination={(page) => handleClickPagination(page, filters, dispatch, setFilters, setRefatch)}
                                    handleNextPagination={() => handleNextPagination(pagination, filters, dispatch, setFilters, setRefatch)}
                                />
                                )}
                            </CardFooter>
                        </Card>
                    </Col>
                ) : (
                    <Col lg="12">
                        <H4 attrH4={{ className: 'sub-title mb-5 text-center' }}>{!cards ? 'Sumber Data Tidak Tersedia' : 'Data Tidak Ditemukan'}</H4>
                    </Col>
                )
            )}

            
        </Fragment>
    );
};

export default MembershipBox;