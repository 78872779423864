import {
	fetchVouchers,
	deleteVoucher,
	replicateVoucher
} from "../../../Features/Learning/Vouchers/Utils/VoucherUtils";
import Swal from "sweetalert2";

/* **************************************************** */
/* CRUD Voucher                                         */
/* **************************************************** */

export const fetchVoucher = (dispatch, filters, refetch) => {
	dispatch(fetchVouchers(filters));
};

export const handleDeleteVoucher = async (dispatch, id, setRefetch) => {
	try {
		Swal.fire({
			title: "Apakah anda yakin?",
			text: "Anda tidak dapat mengembalikan data yang telah dihapus!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
		}).then(async (result) => {
			if (result.isConfirmed) {
				await dispatch(deleteVoucher(id));
				setRefetch((prevRefetch) => !prevRefetch);
				Swal.fire("Terhapus!", "Data telah dihapus.", "success");
			}
		});
	} catch (error) {
		console.error("Delete error:", error);
	}
};

export const handleEditVoucher = async (dispatch, item, setField) => {
	const fieldsToUpdate = [
		"valid_until",
		"is_percentage",
		"code",
		"is_percentage",
		"discount",
		"minimum_nominal",
	];

	fieldsToUpdate.forEach((field) => {
		dispatch(setField({ field, value: item[field] }));
	});
	const selectedMembership = item.membership
		? item.membership.map((item) => ({
				value: item.id,
				label: item.title,
		  }))
		: [];
	dispatch(setField({ field: "membership", value: selectedMembership }));
	dispatch(setField({ field: "isUpdate", value: true }));
	dispatch(setField({ field: "id", value: item.id }));
};

export const handleGetDetailVoucher = async (dispatch, item, setDetail) => {
	dispatch(setDetail(item));
};

export const handleReplicateVoucher = async (dispatch, item, setRefetch) => {
	try {
		Swal.fire({
			title: 'Apakah anda yakin?',
			html: `Anda akan menduplikasi voucher <strong>${item.code}</strong> ini!`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33'
		}).then(async (result) => {
			if (result.isConfirmed) {
				const response = await dispatch(replicateVoucher(item.id));
				if (response.payload.status === 201) {
					setRefetch(prevRefetch => !prevRefetch);
					Swal.fire(
						'Berhasil!',
						'Data telah diduplikasi.',
						'success'
					)
				} else {
					Swal.fire(
						'Gagal!',
						'Data gagal diduplikasi.',
						'error'
					)
				}
			}
		})
	} catch (error) {
		console.error("Delete error:", error);
	}
}

/* **************************************************** */
/* Pagination                                           */
/* **************************************************** */
export const handleBackPagination = (
	pagination,
	filters,
	dispatch,
	setFilters,
	setRefetch
) => {
	if (pagination.current_page > 1) {
		dispatch(
			setFilters({
				...filters,
				page: pagination.current_page - 1,
			})
		);
		setRefetch((prevRefetch) => !prevRefetch);
	}
};

export const handleNextPagination = (
	pagination,
	filters,
	dispatch,
	setFilters,
	setRefetch
) => {
	console.log(pagination.current_page, pagination.last_page);
	if (pagination.current_page < pagination.last_page) {
		dispatch(
			setFilters({
				...filters,
				page: pagination.current_page + 1,
			})
		);
		setRefetch((prevRefetch) => !prevRefetch);
	}
};

export const handleClickPagination = (
	page,
	filters,
	dispatch,
	setFilters,
	setRefetch
) => {
	// console.log(page);
	dispatch(
		setFilters({
			...filters,
			page: page,
		})
	);
	setRefetch((prevRefetch) => !prevRefetch);
};
