import React, { Fragment, useEffect, useState } from 'react';
import { PlusCircle } from 'react-feather';
import { Card, Col, Row, CardBody, CardHeader, CardFooter, Table } from 'reactstrap';
import { H1, H2, H3, H4, H5, H6, Image, Btn, LI, P, UL, Spinner } from '../../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setDetail, setFilters } from '../../../Features/Learning/Users/userSlice';
import { setField, resetState } from '../../../Features/Learning/Users/addUserSlice';
import {
    fetchUser,
    handleDeleteUser,
    handleEditUser,
    getDetailUser,
    handleNextPagination,
    handleBackPagination,
    handleClickPagination,
    handleLoginAs,
    handleCopy
} from './UsersUtils';
import PaginationComponent from '../Common/Pagination';
import ThreeDotMenu from '../Common/ThreeDotMenu';


const UserBox = () => {
    const history = useNavigate()
    const dispatch = useDispatch()
    const filters = useSelector(state => state.User.filters)
    const isLoading = useSelector(state => state.User.isLoading)
    const cards = useSelector(state => state.User.data.data)
    const pagination = useSelector(state => state.User.data.pagination)
    const [refatch, setRefatch] = useState(false)

    useEffect(() => {
        fetchUser(dispatch, filters, refatch)
    }, [refatch])

    return (
        <Fragment>
            {isLoading ? (
                <Col lg="12">
                    <div className="loader-box">
                        <Spinner attrSpinner={{ className: 'loader-30' }} />
                    </div>
                    <H6 attrH6={{ className: 'sub-title mb-0 text-center' }}>{'Data Loading...'}</H6>
                </Col>
            ) : (
                cards && cards.length > 0 && (
                    <Col sm="12 order-2">
                        <Card>
                            <CardBody>
                                <Table className="table-bordered table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="bg-primary text-light">{'#'}</th>
                                            <th scope="col" className="bg-primary text-light">{'Nama'}</th>
                                            <th scope="col" className="bg-primary text-light">{'Telp'}</th>
                                            <th scope="col" className="bg-primary text-light">{'Progress'}</th>
                                            <th scope="col" className="bg-primary text-light text-center" style={{ width: '150px' }}>{'Aksi'}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            cards && cards.map((item, index) =>
                                                <tr key={`user-${index}`} className="table-light">
                                                    <th>{pagination.from + index}</th>
                                                    <td>
                                                        <h6><b>{item.name}</b></h6>
                                                        ({item.email})                                                  </td>
                                                    <td>
                                                        <a href={`https://wa.me/62${item.phone}?text=Halo%20kak%20${item.name},%20saya%20ingin%20bertanya%20terkait..`} target="_blank" rel="noreferrer" title="Hubungi via whatsapp">{item.phone && item.phone.replace(/(\d{4})(\d{4})(\d{4})/, '$1 $2 $3')}</a>
                                                    </td>
                                                    <td className="text-center">
                                                        <Btn
                                                            attrBtn={{
                                                                title: 'Link User Report',
                                                                color: 'link',
                                                                outline: true,
                                                                className: 'p-1',
                                                                onClick: () => handleCopy(item.parent_user.token)
                                                            }}
                                                        >
                                                            <i className="icofont icofont-copy-alt mx-auto fa-lg"></i>
                                                        </Btn>
                                                        {item.parent_user && item.parent_user.phone && (
                                                        <Btn
                                                            attrBtn={{
                                                                title: 'Send Link via Whatsapp',
                                                                color: 'link',
                                                                outline: true,
                                                                className: 'p-1',
                                                                onClick: () => 
                                                                window.open(`https://wa.me/62${item.parent_user.phone}?text=Halo%20wali%20dari%20ananda%20${item.name}.%20Salam%20kenal%2C%20kami%20dari%20tim%20brofesional%20by%20Bimbel%20YEC.%0A%0ABerikut%20kami%20kirimkan%20link%20laporan%20progress%20belajar%20peserta%20bimbingan%20Brofesional%20by%20Bimbel%20YEC%20di%20program%20SKD%20CPNS%202024%0A%0ALaporan%20Belajar%20Siswa%20dapat%20di%20klik%20di%3A%20${process.env.REACT_APP_USER_URL}/report/member/${item.parent_user.token}%0A%0ATerima%20kasih%20telah%20berjuang%20bersama%20tim%20brofesional.%20Semoga%20mendapat%20hasil%20terbaik.`)
                                                            }}
                                                        >
                                                            <i className="icofont icofont-send-mail mx-auto fa-2x"></i>
                                                        </Btn>
                                                        )}
                                                    </td>
                                                    <td className='text-center'>
                                                        <ThreeDotMenu
                                                            visibleActions={[
                                                                <Link to={`${process.env.PUBLIC_URL}/users/user-detail/${item.id}`} title="Detail User" className="p-1" onClick={() => getDetailUser(dispatch, item, setDetail)}>
                                                                    <i className="fa fa-info-circle mx-auto fa-lg"></i>
                                                                </Link>,
                                                                <Link to={`${process.env.PUBLIC_URL}/users/user-add/${item.id}`} title="Edit" className="p-1" onClick={() => handleEditUser(dispatch, item, setField)}>
                                                                    <i className="fa fa-edit mx-auto text-secondary fa-lg"></i>
                                                                </Link>
                                                            ]}
                                                            dropdownActions={[
                                                                <Btn
                                                                    attrBtn={{
                                                                        title: 'Login As',
                                                                        color: 'link',
                                                                        outline: true,
                                                                        className: 'p-1',
                                                                        onClick: () => handleLoginAs(item.id, item.name)
                                                                    }}
                                                                >
                                                                    <i className="fa fa-sign-in mx-auto fa-lg"></i> Login As
                                                                </Btn>,
                                                                <Btn
                                                                    attrBtn={{
                                                                        title: 'Delete',
                                                                        color: 'link',
                                                                        outline: true,
                                                                        className: 'p-1',
                                                                        onClick: () => handleDeleteUser(dispatch, item.id, setRefatch)
                                                                    }}
                                                                >
                                                                    <i className="fa fa-trash-o mx-auto text-danger fa-lg"></i> Hapus
                                                                </Btn>
                                                            ]}
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </CardBody>
                            <CardFooter>
                                {pagination && (
                                <PaginationComponent
                                    pagination={pagination}
                                    handleBackPagination={() => handleBackPagination(pagination, filters, dispatch, setFilters, setRefatch)}
                                    handleClickPagination={(page) => handleClickPagination(page, filters, dispatch, setFilters, setRefatch)}
                                    handleNextPagination={() => handleNextPagination(pagination, filters, dispatch, setFilters, setRefatch)}
                                />
                                )}
                            </CardFooter>
                        </Card>
                    </Col>
                )
            )}

            {!cards || cards.length === 0 && (
                <Col lg="12">
                    <H3 attrH3={{ className: 'sub-title mb-5 text-center' }}>{!cards ? 'Sumber Data Tidak Tersedia' : 'Data Tidak Ditemukan'}</H3>
                </Col>
            )}

            
        </Fragment>
    );
};

export default UserBox;