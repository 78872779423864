import React, { Fragment, useEffect, useState } from "react";
import { PlusCircle } from "react-feather";
import {
	Card,
	Col,
	Row,
	CardBody,
	CardHeader,
	CardFooter,
	Table,
	Label,
} from "reactstrap";
import {
	H1,
	H2,
	H3,
	H4,
	H5,
	H6,
	Image,
	Btn,
	LI,
	P,
	UL,
	Spinner,
	Badges,
} from "../../../AbstractElements";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
	setFilters,
	setDetail,
} from "../../../Features/Learning/Vouchers/voucherSlice";
import {
	setField,
	resetState,
} from "../../../Features/Learning/Vouchers/addVoucherSlice";
import { fetchMembership } from "../../../Component/Learning/Membership/MembershipsUtils";
import {
	fetchVoucher,
	handleDeleteVoucher,
	handleEditVoucher,
	handleGetDetailVoucher,
	handleBackPagination,
	handleNextPagination,
	handleClickPagination,
	handleReplicateVoucher
} from "./VoucherUtils";
import CurrencyFormat from "../Common/GenerateContent/CurrencyFormatter";
import PaginationComponent from "../Common/Pagination";
import Moment from "moment";
import "moment/locale/id";

const VoucherContain = () => {
	const dispatch = useDispatch();
	const filters = useSelector((state) => state.Voucher.filters);
	const isLoading = useSelector((state) => state.Voucher.isLoading);
	const cards = useSelector((state) => state.Voucher.data.data);
	const pagination = useSelector((state) => state.Voucher.data.pagination);
	const [refatch, setRefatch] = useState(false);

	useEffect(() => {
		fetchVoucher(dispatch, filters, refatch);
		window.scrollTo(0, 0);
	}, [refatch]);

	// make a function for check expired date of voucher
	const checkExpiredDate = (date) => {
		const now = Moment().format("YYYY-MM-DD HH:mm:ss");
		if (date < now) {
			return true;
		}
		return false;
	};

	return (
		<Fragment>
			{isLoading ? (
				<Col lg="12">
					<div className="loader-box">
						<Spinner attrSpinner={{ className: "loader-30" }} />
					</div>
					<H6 attrH6={{ className: "sub-title mb-0 text-center" }}>
						{"Data Loading..."}
					</H6>
				</Col>
			) : (
				cards &&
				cards.length > 0 && (
					<Col sm="12 order-2">
						<Card>
							<CardBody>
								<Table className="table-bordered table-striped table-hover">
									<thead>
										<tr>
											<th
												scope="col"
												className="bg-primary text-light"
												style={{ verticalAlign: "middle" }}>
												#
											</th>
											<th
												scope="col"
												className="bg-primary text-light"
												style={{ verticalAlign: "middle" }}>
												Kode Voucer
											</th>
											<th
												scope="col"
												className="bg-primary text-light text-center"
												style={{ verticalAlign: "middle" }}>
												Diskon
											</th>
											<th
												scope="col"
												className="bg-primary text-light text-center"
												style={{ verticalAlign: "middle" }}>
												Nominal Minimum
											</th>
											<th
												scope="col"
												className="bg-primary text-light text-center"
												style={{ verticalAlign: "middle" }}>
												Masa Berlaku
											</th>
											<th
												scope="col"
												className="bg-primary text-light text-center"
												style={{ verticalAlign: "middle" }}>
												Total
											</th>
											<th
												scope="col"
												className="bg-primary text-light text-center"
												style={{ width: "140px", verticalAlign: "middle" }}>
												Aksi
											</th>
										</tr>
									</thead>
									<tbody>
										{cards &&
											cards.map((item, index) => (
												<tr key={`user-${index}`} className="table-light">
													<td>{pagination.from + index}</td>
													<td>{item.code}</td>
													<td>
														{item.is_percentage ? (
															item.discount + "%"
														) : (
															<CurrencyFormat amount={item.discount} />
														)}
													</td>
													<td>
														<CurrencyFormat amount={item.minimum_nominal} />
													</td>
													<td className="text-center">
														<Label className="badge badge-success">
														{Moment.utc(item.valid_until).format(
															"DD MMM YYYY"
														)}{" "}
														</Label>
														{checkExpiredDate(item.valid_until) ? (
															<Label className="badge badge-danger">
																Expired
															</Label>
														) : (
															<Label className="badge badge-success">
																Active
															</Label>
														)}
													</td>
													<td className="text-center">
														<Badges attrBadge={{ color: "primary" }}>
															{item.total_used}
														</Badges>
													</td>
													<td className="text-center">
														<div>
															<Btn
																attrBtn={{
																	title: 'Duplicate Class',
																	color: 'link',
																	outline: true,
																	className: 'p-1',
																	onClick: () => handleReplicateVoucher(dispatch, item, setRefatch)
																}}
															>
																<i className="fa fa-copy mx-auto fa-lg"></i>
															</Btn>
															<Link
																to={`${process.env.PUBLIC_URL}/voucher/detail/${item.id}`}
																title="Detail Voucher"
																className="p-1"
																onClick={() =>
																	handleGetDetailVoucher(
																		dispatch,
																		item,
																		setDetail
																	)
																}>
																<i className="fa fa-info-circle mx-auto fa-lg"></i>
															</Link>
															<Link
																to={`${process.env.PUBLIC_URL}/voucher/add/${item.id}`}
																title="Edit"
																className="p-1"
																onClick={() =>
																	handleEditVoucher(dispatch, item, setField)
																}>
																<i className="fa fa-edit mx-auto text-secondary fa-lg"></i>
															</Link>
															<Btn
																attrBtn={{
																	title: "Delete",
																	color: "link",
																	outline: true,
																	className: "p-1",
																	onClick: () =>
																		handleDeleteVoucher(
																			dispatch,
																			item.id,
																			setRefatch
																		),
																}}>
																<i className="fa fa-trash-o mx-auto text-danger fa-lg"></i>
															</Btn>
														</div>
													</td>
												</tr>
											))}
									</tbody>
								</Table>
							</CardBody>
							<CardFooter>
								{pagination && (
									<PaginationComponent
										pagination={pagination}
										handleBackPagination={() =>
											handleBackPagination(
												pagination,
												filters,
												dispatch,
												setFilters,
												setRefatch
											)
										}
										handleClickPagination={(page) =>
											handleClickPagination(
												page,
												filters,
												dispatch,
												setFilters,
												setRefatch
											)
										}
										handleNextPagination={() =>
											handleNextPagination(
												pagination,
												filters,
												dispatch,
												setFilters,
												setRefatch
											)
										}
									/>
								)}
							</CardFooter>
						</Card>
					</Col>
				)
			)}

			{!cards ||
				(cards.length === 0 && (
					<Col lg="12">
						<H3 attrH3={{ className: "sub-title mb-5 text-center" }}>
							{!cards ? "Sumber Data Tidak Tersedia" : "Data Tidak Ditemukan"}
						</H3>
					</Col>
				))}
		</Fragment>
	);
};

export default VoucherContain;
