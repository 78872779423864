import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Input, Label } from 'reactstrap';
import { Collapse } from 'reactstrap';
import { Search } from 'react-feather';
import { Filter } from 'Constant';
import { H5, Btn } from 'AbstractElements';
import { useSelector, useDispatch } from 'react-redux';
import { setFilters } from 'Features/Learning/MainPrograms/mainProgramSlice';
import { fetchMainPrograms } from 'Component/Learning/MainProgram/MainProgramUtils';
import { getMainProgramCategory } from 'Features/Learning/Categories/Utils/CategoriesUtils';
import Select from 'react-select';

const FindMainProgram = () => {
  const [isFilter, setIsFilter] = useState(true);
  const dispatch = useDispatch()
  const filters = useSelector(state => state.MainProgram.filters)

  const handleClickSearch = (e) => {
    e.preventDefault()
    const newFilters = {
      ...filters,
      'page': 1,
    };
    dispatch(setFilters(newFilters));
    fetchMainPrograms(dispatch, newFilters);
  }

  const handleResetFilter = () => {
    const newFilters = {
      ...filters,
        'filter[category_id]': '',
        'filter[title]': '',
        'sort': '',
    };

    dispatch(setFilters(newFilters));
    fetchMainPrograms(dispatch, newFilters);
  }

  const updateFilters = (filters, dispatch, field, value) => {
    dispatch(setFilters({
      ...filters,
      [field]: value,
    }));
  };

  const handleSortBy = (filters, dispatch) => (e) => {
    const filterValue = e.target.value;
    updateFilters(filters, dispatch, 'sort', filterValue);
  };

  const [selectedValue, setSelectedValue] = useState(null);
  const [dataCategory, setDataCategory] = useState([]);

  const flattenAllData = (data) => {
    const flattened = [];

    data.forEach((category) => {

      if (category.children) {
        category.children.forEach((child) => {
          flattened.push({ label: child.name, value: child.id });
        });
      }
    });

    return flattened;
  };

  const handleSelectChange = (selectedOption) => {
    console.log(selectedOption, 'selectedOption')
    setSelectedValue(selectedOption);
    if (selectedOption === null) {
      dispatch(setFilters ({
        ...filters,
        'filter[category_id]': '',
      }));
    } else {
      dispatch(setFilters ({
        ...filters,
        'filter[category_id]': selectedOption.value,
      }));
    }
  }

  useEffect(() => {
    let isMounted = true; // Flag untuk mengecek apakah komponen masih mounted

    dispatch(getMainProgramCategory())
      .then((response) => {
        if (isMounted) { // Update state hanya jika komponen masih mounted
          const flattenedData = flattenAllData(response.payload.data);
          setDataCategory(flattenedData);
        }
      })
      .catch((error) => {
        console.error('Error fetching options:', error);
      });

    window.scrollTo(0, 0);

    return () => {
      isMounted = false; // Set flag ke false saat komponen di-unmount
    };
  }, []);

  useEffect(() => {
    setSelectedValue(dataCategory.find((item) => item.value === filters['filter[category_id]']));
  } , [dataCategory, filters['filter[category_id]']]);


  return (
    <Fragment>
      <Col xl="12">
        <Card>
          <CardHeader>
            <H5 attrH5={{ className: 'mb-0' }} >
              <Btn attrBtn={{ className: 'btn btn-link ps-0', onClick: () => setIsFilter(!isFilter), color: 'transperant', datastoggle: 'collapse', databstarget: '#collapseicon', ariaexpanded: 'true', ariacontrols: 'collapseicon' }} >{'Temukan Program'}</Btn>
            </H5>
          </CardHeader>
          <Collapse isOpen={isFilter}>
            <div className="collapse show" id="collapseicon" aria-labelledby="collapseicon" data-parent="#accordion">
              <CardBody className="filter-cards-view animate-chk">
                <div className="job-filter">
                  <div className="faq-form">
                    <Input className="form-control" type="text" placeholder="Search.." 
                      value={filters['filter[title]']}
                      onKeyUp={(e) => e.key === 'Enter' ? handleClickSearch(e): null}
                      onChange={(e) => {
                        dispatch(setFilters({
                          ...filters,
                          'filter[title]': e.target.value
                        }));
                      }}
                    />
                    <Search className="search-icon" />
                  </div>
                </div>
                <div className="checkbox-animated mt-0">
                  <div className="learning-header"><span className="f-w-600">Sort by:</span></div>
                  <Label className="d-block" htmlFor="sort-new">
                    <Input className="radio_animated" id="sort-new" type="radio" name="sort"
                      checked={filters.sort === '-id'}
                      value="-id"
                      onChange={handleSortBy(filters, dispatch)}
                    />
                    {'Terbaru'}
                  </Label>
                  <Label className="d-block" htmlFor="sort-old">
                    <Input className="radio_animated" id="sort-old" type="radio" name="sort"
                      checked={filters.sort === 'id'}
                      value="id"
                      onChange={handleSortBy(filters, dispatch)}
                    />
                    {'Terlama'}
                  </Label>
                  <Label className="d-block" htmlFor="sort-az">
                    <Input className="radio_animated" id="sort-az" type="radio" name="sort"
                      checked={filters.sort === 'title'}
                      value="title"
                      onChange={handleSortBy(filters, dispatch)}
                    />
                    {'A-Z'}
                  </Label>
                  <Label className="d-block" htmlFor="sort-za">
                    <Input className="radio_animated" id="sort-za" type="radio" name="sort"
                      checked={filters.sort === '-title'}
                      value="-title"
                      onChange={handleSortBy(filters, dispatch)}
                    />
                    {'Z-A'}
                  </Label>
                  <Label className="d-block" htmlFor="sort-order-asc">
                    <Input className="radio_animated" id="sort-order-asc" type="radio" name="sort"
                      checked={filters.sort === 'order'}
                      value="order"
                      onChange={handleSortBy(filters, dispatch)}
                    />
                    {'Urutan Naik'}
                  </Label>
                  <Label className="d-block" htmlFor="sort-order-desc">
                    <Input className="radio_animated" id="sort-order-desc" type="radio" name="sort"
                      checked={filters.sort === '-order'}
                      value="-order"
                      onChange={handleSortBy(filters, dispatch)}
                    />
                    {'Urutan Turun'}
                  </Label>
                </div>
                <div className="checkbox-animated mt-0">
                  <div className="learning-header"><span className="f-w-600">Kategori Program:</span></div>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Pilih Kategori..."
                    isClearable
                    isSearchable
                    name="color"
                    options={dataCategory}
                    onChange={handleSelectChange}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    value={selectedValue}
                  />
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Btn attrBtn={{ color: 'primary text-center p-1', onClick: handleClickSearch }}>{Filter}</Btn>
                  </div>
                  <div className="col-md-6">
                    <Btn attrBtn={{ color: 'warning text-center p-1', onClick: handleResetFilter }}>{'Reset'}</Btn>
                  </div>
                </div>
              </CardBody>
            </div>
          </Collapse>
        </Card>
      </Col>
    </Fragment>
  );
};

export default FindMainProgram;