import React, { Fragment, useState } from "react";
import { Container, Row, Col, Card } from "reactstrap";
import { PlusCircle } from "react-feather";
import { Btn } from "../../../AbstractElements";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import TentorContain from "../../../Component/Classes/Tentor/TentorContain";
import TentorFilter from "../../../Component/Classes/Tentor/Filter";
import FindTentor from '../../Classes/Tentor/Filter/FindTentor';

const Tentors = () => {
	const cards = useSelector((state) => state.Tentor.data.data);
	const dispatch = useDispatch();

	return (
		<Fragment>
			<Col md="12" className="project-list">
				{cards && (
					<Card className="px-3">
						<FindTentor />
					</Card>
				)}
			</Col>
			<Col xl="12" className="xl-80">
				<Row>
					<TentorContain />
				</Row>
			</Col>
		</Fragment>
	);
};
export default Tentors;
