import { axiosInstance } from "./axiosInstance";

export const TentorMemberServices = {
	create: async (data) => {
		return await axiosInstance.post("/api/feature/class/tentor-member/admin", data);
	},
	update: async (id, data) => {
		return await axiosInstance.post(
			`/api/feature/class/tentor-member/admin/update/${id}`,
			data
		);
	},
	delete: async (id) => {
		return await axiosInstance.delete(`/api/feature/class/tentor-member/admin/delete/${id}`);
	},
};
