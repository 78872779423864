import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, CardBody, CardHeader, CardFooter, Table } from 'reactstrap';
import { Minus } from 'react-feather';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { H6, H5, Btn, Spinner, Badges } from '../../../../AbstractElements';
import { setDetail, setFilters } from '../../../../Features/Classes/Schedule/ScheduleSlice';
import { setField } from '../../../../Features/Classes/Schedule/addScheduleSlice';
import {
    fetchSchedule,
    handleDeleteSchedule,
    handleEditSchedule,
    handleGetDetailSchedule,
    handleNextPagination,
    handleBackPagination,
    handleClickPagination
} from '../../Schedule/ScheduleUtils';
import PaginationComponent from '../../../Learning/Common/Pagination';
import ScheduleFilter from '../../../Classes/Schedule/Filter/FindSchedule';
import Moment from 'moment';
import 'moment/locale/id';


const DetailScheduleClassContain = () => {
    const { classId } = useParams();
    const dispatch = useDispatch();
    const detailClass = useSelector(state => state.Class.detail);
    const filters = useSelector(state => state.Schedule.filters)
    const isLoading = useSelector(state => state.Schedule.isLoading)
    const data = useSelector(state => state.Schedule.data.data)
    const pagination = useSelector(state => state.Schedule.data.pagination)
    const [refatch, setRefatch] = useState(false)

    useEffect(() => {
        const filterDefault = {
            ...filters,
            "filter[class_id]": classId,
        }
        dispatch(setFilters(filterDefault))
        fetchSchedule(dispatch, filterDefault, refatch)
    }, [refatch])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <Fragment>
            <Col sm="12 order-2">
                <Card>
                    <CardBody>
                        <ScheduleFilter />
                    </CardBody>
                </Card>
                {isLoading ? (
                    <Col lg="12">
                        <div className="loader-box">
                            <Spinner attrSpinner={{ className: 'loader-30' }} />
                        </div>
                        <H6 attrH6={{ className: 'mb-0 text-center' }}>{'Data Loading...'}</H6>
                    </Col>
                ) : (
                    data && data.length === 0 ? (
                        <Card>
                            <CardHeader>
                                <H6 attrH6={{ className: 'mb-0 text-center' }}>{filters['filter[name]'] || filters['filter[place]'] || filters['filter[tentor_id]'] ? 'Sesi tidak ditemukan' : 'Belum ada sesi terjadwal'}</H6>
                            </CardHeader>
                        </Card>
                    ) : (
                    <Col sm="12" xl="12" lg="12" className="xl-100 box-col-12 order-1">
                        <Card className="social-widget-card">
                            <CardHeader className="pb-0">
                                <H5 attrH5={{ className: 'mb-0' }}>Jadwal Kelas {detailClass.name}</H5>
                            </CardHeader>
                            <CardBody>
                                <Table className="table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="bg-primary text-light" style={{ verticalAlign: 'middle' }}>#</th>
                                            <th scope="col" className="bg-primary text-light" style={{ verticalAlign: 'middle' }}>Nama Sesi</th>
                                            <th scope="col" className="bg-primary text-light" style={{ verticalAlign: 'middle' }}>Tentor</th>
                                            <th scope="col" className="bg-primary text-light text-center" style={{ verticalAlign: 'middle' }}>Ruang</th>
                                            <th scope="col" className="bg-primary text-light text-center" style={{ verticalAlign: 'middle' }}>Tanggal</th>
                                            <th scope="col" className="bg-primary text-light text-center" style={{ verticalAlign: 'middle' }}>Waktu (WIB)</th>
                                            {/* <th scope="col" className="bg-primary text-light text-center" style={{ verticalAlign: 'middle' }}>Scan QR</th> */}
                                            <th scope="col" className="bg-primary text-light text-center" style={{ width: '50px', verticalAlign: 'middle' }}>Jumlah Siswa</th>
                                            <th scope="col" className="bg-primary text-light text-center" style={{ verticalAlign: 'middle' }}>Prasesi</th>
                                            <th scope="col" className="bg-primary text-light text-center" style={{ width: '135px', verticalAlign: 'middle' }}>Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data && data.map((item, index) => (
                                        <tr key={index}>
                                            <th scope="row">{pagination.from + index}</th>
                                            <td>
                                            {item.name}
                                            {item.session_end_at && item.session_end_at < Moment().format() && (
                                                <i className="fa fa-check-circle text-success fa-lg ms-1"></i>
                                            )}
                                            </td>
                                            <td>{item.tentor?.name}</td>
                                            <td className="text-center">{item.place}</td>
                                            <td className="text-center">{Moment(item.session_start_at).format('DD/MM/YYYY')}</td>
                                            <td className="text-center">{Moment(item.session_start_at).format('HH:mm')} - {Moment(item.session_end_at).format('HH:mm')}</td>
                                            <td className="text-center">
                                                <Badges attrBadge={{ color: 'warning' }} >
                                                    {item.attendance.length || 0}
                                                </Badges>
                                            </td>
                                            <td className="text-center">
                                                {item.material && item.material.id ?
                                                    <Badges attrBadge={{ color: 'primary' }} >Ada</Badges>
                                                : 
                                                    <Minus size={25} className="text-dark me-1" />
                                                }
                                            </td>
                                            <td className="text-center">
                                                <Link to={`${process.env.PUBLIC_URL}/classes/schedule-class-detail/${classId}/${item.id}`} 
                                                title="Detail Sesi" className="p-1" 
                                                onClick={() => handleGetDetailSchedule(dispatch, item, setDetail)}
                                                >
                                                    <i className="fa fa-info-circle mx-auto fa-lg"></i>
                                                </Link>
                                                <Link to={`${process.env.PUBLIC_URL}/classes/schedule-class-add/${classId}/${item.id}`} 
                                                title="Edit" className="p-1" 
                                                onClick={() => handleEditSchedule(dispatch, item, setField, setDetail)}
                                                >
                                                    <i className="fa fa-edit mx-auto text-secondary fa-lg"></i>
                                                </Link>
                                                <Btn
                                                    attrBtn={{
                                                        title: 'Delete',
                                                        color: 'link',
                                                        outline: true,
                                                        className: 'p-1',
                                                        onClick: () => handleDeleteSchedule(dispatch, item.id, setRefatch)
                                                    }}
                                                >
                                                    <i className="fa fa-trash-o mx-auto text-danger fa-lg"></i>
                                                </Btn>
                                            </td>
                                        </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </CardBody>
                            <CardFooter>
                                {pagination && (
                                    <PaginationComponent
                                        pagination={pagination}
                                        handleBackPagination={() => handleBackPagination(pagination, filters, dispatch, setFilters, setRefatch)}
                                        handleClickPagination={(page) => handleClickPagination(page, filters, dispatch, setFilters, setRefatch)}
                                        handleNextPagination={() => handleNextPagination(pagination, filters, dispatch, setFilters, setRefatch)}
                                    />
                                )}
                            </CardFooter>
                        </Card>
                    </Col>
                    )
                )}
            </Col>
        </Fragment >
    );
};
export default DetailScheduleClassContain;