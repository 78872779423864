import React, { Fragment, useEffect, useState } from 'react';
import { PlusCircle } from 'react-feather';
import { Card, Col, Row, CardBody, CardHeader, CardFooter, Modal, ModalHeader, ModalBody, Input } from 'reactstrap';
import { H1, H2, H3, H4, H5, H6, Image, Btn, LI, P, UL, Spinner } from '../../../AbstractElements';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Post, Discard } from "../../../Constant";
import { markAsSolved } from '../../../Features/Learning/Ticket/Utils/TicketUtils';
import RawComponent from '../../../Component/Learning/Common/RawHTML';
import Moment from 'moment';
import 'moment/locale/id';
import Swal from 'sweetalert2';


const TicketDetailContain = ({refatch, setRefatch}) => {
    const isLoading = useSelector(state => state.Ticket.isLoading)
    const cards = useSelector(state => state.Ticket.data.data)
    const dispatch = useDispatch()
    const [openPopup, setOpenPopup] = useState(false)
    const [feedback, setFeedback] = useState({id: null, feedback: ''})

    const handleSubmit = async (data) => {
        try {
            const result = await Swal.fire({
                title: 'Apakah anda yakin?',
                text: "",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ya',
                cancelButtonColor: '#d33'
            });

            if (result.isConfirmed) {
                const response = await dispatch(markAsSolved(data));
                console.log(response);
                if (response.payload.message = 'success') {
                    setRefatch(prevRefetch => !prevRefetch);
                    Swal.fire(
                        'Terselesaikan!',
                        'Umpan balik berhasil disimpan.',
                        'success'
                    )
                    setOpenPopup(false);
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Permintaan gagal. Mohon coba lagi.',
                    });
                    setOpenPopup(false);
                }
            }
        } catch (error) {
            console.error("Delete error:", error);
        }
    };


    return (
        <Fragment>
            {isLoading ? (
                <Col lg="12">
                    <div className="loader-box">
                        <Spinner attrSpinner={{ className: 'loader-30' }} />
                    </div>
                    <H6 attrH6={{ className: 'sub-title mb-0 text-center' }}>{'Data Loading...'}</H6>
                </Col>
            ) : (
                cards && cards.length > 0 && (
                    <Col sm="12 order-2">
                      {cards && cards.map((item, index) =>
                        <Card key={`Contain-${index}`} className="social-widget-card">
                            <CardHeader className="pb-0">
                                <H2 attrH2={{ className: 'card-title mb-0' }}>{item.title}</H2>
                            </CardHeader>
                            <CardBody>
                                <Col>
                                    <Row>
                                        <Col>
                                            <H5 attrH5={{ className: 'counter mb-0 font-roboto font-primary mt-4' }}>Deskripsi Laporan: </H5>
                                            <H6 attrH6={{ className: 'font-roboto mt-2' }}>{item.description}</H6>
                                        </Col>
                                        <Col>
                                            <H5 attrH5={{ className: 'counter mb-0 font-roboto font-primary mt-4' }}>Status Laporan: </H5>
                                            <H6 attrH6={{ className: 'font-roboto mt-2' }}>{item.is_solved ? <i className="fa fa-check-square-o mx-auto text-success fa-lg"></i> : <i className="icofont icofont-close-squared-alt  mx-auto text-danger fa-lg"></i>}{item.is_solved ? ' Sudah' : ' Belum'} Ditanggapi</H6>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <H5 attrH5={{ className: 'counter mb-0 font-roboto font-primary mt-4' }}>Soal yang dilaporkan(label soal)</H5>
                                            <H6 attrH6={{ className: 'font-roboto mt-2' }}><RawComponent htmlContent={item.question.title} /></H6>
                                        </Col>
                                        {item.is_solved === 1 && (
                                            <Col>
                                                <H5 attrH5={{ className: 'counter mb-0 font-roboto font-primary mt-4' }}>Umpan Balik:{' '} 
                                                {item.feedback && (
                                                    <span title='Edit Umpan Balik' style={{ cursor: "pointer" }} onClick={() => {
                                                        setOpenPopup(true);
                                                        setFeedback({id: item.id, feedback: item.feedback});
                                                    }}>
                                                        <i className="fa fa-edit mx-auto text-success fa-lg"></i>
                                                    </span>
                                                )}
                                                </H5>
                                                <H6 attrH6={{ className: 'font-roboto mt-2' }}>{item.feedback ? item.feedback : 'Belum ada umpan balik'}</H6>
                                            </Col>
                                        )}
                                      </Row>
                                </Col>
                            </CardBody>
                            <CardFooter>
                                <Row>
                                    <Col>
                                        <H6 attrH6={{ className: 'font-roboto mb-2' }}>Dilaporkan oleh: {item.user.name}</H6>
                                        <H6 attrH6={{ className: 'font-roboto' }}>{Moment(item.created_at).format('dddd, D MMMM YYYY')} - {Moment(item.created_at).format('HH:mm:ss')} WIB</H6>
                                    </Col>
                                    <Col className='text-end'>
                                        <Link to={`${process.env.PUBLIC_URL}/user-tickets`}>
                                            <Btn attrBtn={{ color: 'warning', className: 'btn btn-primary m-r-10' }}>
                                                <i className="fa fa-chevron-circle-left fa-lg me-2"></i>Back
                                            </Btn>
                                        </Link>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>
                      )}
                    </Col>
                )
            )}

            {cards && cards.length === 0 && (
                <Col lg="12">
                    <H3 attrH3={{ className: 'sub-title mb-5 text-center' }}>{'Data Not Found...'}</H3>
                </Col>
            )}
            

            {/* Modal */}
            <Modal
                isOpen={openPopup}
                toggle={() => setOpenPopup(!openPopup)}
                size="lg">
                <Fragment>
                    <ModalHeader toggle={() => setOpenPopup(!openPopup)}>
                        Edit Umpan Balik
                    </ModalHeader>
                    <ModalBody>
                        <Input
                            type="textarea"
                            name="feedback"
                            id="feedback"
                            placeholder="Masukkan umpan balik"
                            value={feedback.feedback}
                            onChange={(e) => setFeedback({id: feedback.id, feedback: e.target.value})}
                        />
                        <div className="btn-showcase text-end mt-3">
                            <Btn
                                attrBtn={{
                                    color: "primary",
                                    type: "submit",
                                    onClick: () => {
                                        handleSubmit(feedback);
                                        setOpenPopup(!openPopup);
                                        setRefatch(!refatch);
                                    },
                                }}>
                                {Post}
                            </Btn>
                            <Btn
                                attrBtn={{
                                    color: "light",
                                    type: "reset",
                                    onClick: () => setOpenPopup(!openPopup),
                                }}>
                                {Discard}
                            </Btn>
                        </div>
                    </ModalBody>
                </Fragment>
            </Modal>


        </Fragment>
    );
};

export default TicketDetailContain;