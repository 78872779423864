import { createAsyncThunk } from '@reduxjs/toolkit';
import { PrivateServices } from '../../../../../Services/axios/Private.Service';
import { checkFilter } from '../../../../Common/CommonUtils';
import Swal from 'sweetalert2';

export const fetchPrivateSchedules = createAsyncThunk(
    'PrivateSchedules/fetchPrivateSchedules',
    async (filters) => {
        try {
            let filter = checkFilter(filters);
            const response = await PrivateServices.getAll(filter);
            return response.data;
        } catch (error) {
            if (error.response) {
                const statusCode = error.response.status;
                let errorMessage = 'Terjadi kesalahan saat mengambil data. Mohon coba lagi.';

                if (statusCode === 400) {
                    errorMessage = 'Permintaan tidak valid.';
                } else if (statusCode === 401) {
                    errorMessage = 'Anda tidak diotorisasi untuk mengakses sumber daya ini.';
                } else if (statusCode === 404) {
                    errorMessage = 'Sumber daya tidak ditemukan.';
                } else if (statusCode === 500) {
                    errorMessage = 'Terjadi kesalahan internal server. Mohon hubungi pengembang.';
                }

                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: errorMessage,
                });

            }
            else if (error.request) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Permintaan gagal. Mohon periksa koneksi internet Anda.',
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Terjadi kesalahan. Mohon coba lagi nanti.',
                });
            }
            throw error;
        }
    }
);