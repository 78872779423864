import React, { Fragment, useState } from 'react';
import { Row, Col, Input, Button, InputGroup, InputGroupText } from 'reactstrap';
import { Search } from 'react-feather';
import { Btn } from '../../../../AbstractElements';
import { useSelector, useDispatch } from 'react-redux';
import { setFilters } from '../../../../Features/Learning/Memberships/membershipSlice';
import { fetchMembership } from '../MembershipsUtils';
import { resetState } from 'Features/Learning/Memberships/addMembershipSlice';
import { Link } from "react-router-dom";
import Select from 'react-select';

const FindMembership = () => {
  const dispatch = useDispatch()
  const filters = useSelector(state => state.Membership.filters)
  const [selectedSortValue, setSelectedSortValue] = useState(null)

  const handleAdd = (e) => {
    dispatch(resetState());
  };

  const handleClickSearch = (e) => {
    e.preventDefault()
    const newFilters = {
      ...filters,
      'page': 1,
    };
    dispatch(setFilters(newFilters));
    fetchMembership(dispatch, newFilters);
  }


  const handleResetFilter = (e) => {
    e.preventDefault()
    const newFilters = {
      ...filters,
        "filter[id]": "",
        "filter[title]": "",
        "filter[price]": "",
        "filter[duration_days]": "",
        "filter[is_online]": "",
        "filter[is_not_attached_to_main_program]": "",
        "sort": "",
        "page": 1,
    };

    dispatch(setFilters(newFilters));
    fetchMembership(dispatch, newFilters);
    setSelectedSortValue(null);
  }

  const options = [
    { value: '-title', label: 'A-Z' },
    { value: 'title', label: 'Z-A' },
    { value: '-id', label: 'Terbaru' },
    { value: 'id', label: 'Terlama' },
  ];

  const handleSelectChangeSort = (selectedOption) => {
    setSelectedSortValue(selectedOption);
    const newFilters = {
      ...filters,
      'sort': selectedOption === null ? '' : selectedOption.value,
    }
    dispatch(setFilters(newFilters));
    fetchMembership(dispatch, newFilters);
  }


  return (
    <Fragment>
      <Col xl="12">
        <div className="job-filter">
          <div className="faq-form">
            <Row className="m-0">
              <Col className="d-flex justify-content-start align-items-top mt-4" xl="4">
                <Link
                  to={`${process.env.PUBLIC_URL}/membership/membership-add`}
                  onClick={handleAdd}>
                  <Btn attrBtn={{ color: "primary" }}>
                    <i className="fa fa-plus-circle fa-lg"></i> Tambah Membership
                    Baru
                  </Btn>
                </Link>
              </Col>
              <Col className="mt-2 d-flex justify-content-end align-items-center px-0" xl="8">
                <Row>
                  <Col xs={8} className="d-flex justify-content-between align-items-center">
                    <InputGroup>
                      <InputGroupText>
                        <Search size={14} />
                      </InputGroupText>
                      <Input className="form-control" type="text" placeholder="Cari membership.."
                        value={filters['filter[title]']}
                        onKeyUp={(e) => e.key === 'Enter' ? handleClickSearch(e) : null}
                        onChange={(e) => {
                          dispatch(setFilters({
                            ...filters,
                            'filter[title]': e.target.value
                          }));
                        }}
                      />
                    </InputGroup>
                  </Col>
                  <Col xs={4}>
                    <Select
                      options={options}
                      className="js-example-basic-single"
                      isMulti={false}
                      isClearable={true}
                      placeholder="Sort by.."
                      onChange={handleSelectChangeSort}
                      value={selectedSortValue}
                    />
                  </Col>
                  <Col xs={12} className="d-flex justify-content-end align-items-center px-0 mt-2">
                    <Button color="warning me-2" onClick={handleResetFilter}>Reset</Button>
                    <Button color="primary me-2" onClick={handleClickSearch}>Filter</Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    </Fragment>
  );
};

export default FindMembership;