import { createSlice } from "@reduxjs/toolkit";
import { submitBlog } from "./Utils/BlogUtils";

const initialState = {
	id: "",
	title: "",
	slug: "",
	old_slug: "",
	description: "",
	thumbnail: "",
	link: "",
	category: "",
	order: "",
	is_hide: 0,
	isUpdate: false,
	isLoading: false,
};

const addBlog = createSlice({
	name: "addBlog",
	initialState: initialState,
	reducers: {
		setField: (state, action) => {
			const { field, value } = action.payload;
			state[field] = value;
		},
		resetState: (state) => {
			return initialState;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(submitBlog.pending, (state) => {
				// Handle loading state
				state.isLoading = true;
			})
			.addCase(submitBlog.fulfilled, (state, action) => {
				// Handle success state
				console.log("fulfilled action");
				state.isLoading = false;
			})
			.addCase(submitBlog.rejected, (state, action) => {
				// Handle error state
				console.log(action.error.message, "error action");
				state.isLoading = false;
			});
	},
});

export const { setField, resetState } = addBlog.actions;

export default addBlog.reducer;
