import React from "react";
import { useState } from "react";
import {
	Image,
	H6,
	H5,
	H4,
	LI,
	UL,
	P,
	Btn,
	Spinner,
} from "../../../../AbstractElements";
import {
	Col,
	Card,
	CardBody,
	CardHeader,
	CardFooter,
	Media,
	Table,
	Row,
	Form,
	FormGroup,
	Label,
	Input,
} from "reactstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import {
	handleEditTryout,
	handleGetDetailTryout,
} from "../../../../Component/Learning/Tryout/TryoutUtils";
import {
	handleEditCourse,
	handleGetDetailCourse,
} from "../../../../Component/Learning/Courses/CoursesUtils";
import {
	attachDetachCourseMemberships,
	attachDetachTryoutMemberships,
	fetchMemberships,
} from "../../../../Features/Learning/Memberships/Utils/MembershipUtils";
import { setField as setFieldCourse } from "../../../../Features/Learning/Courses/addCourseSlice";
import { setDetail as setDetailCourse } from "../../../../Features/Learning/Courses/courseSlice";
import { setDetail as setDetailTryout } from "../../../../Features/Learning/Tryout/tryoutSlice";
import { setField as setFieldTryout } from "../../../../Features/Learning/Tryout/addTryoutSlice";
import dragula from "react-dragula";
import AdvanceOrderTryoutCourse from "./AdvanceOrderTryoutCourse";

const MembershipList = ({
	data,
	title,
	items,
	itemInverse,
	detail,
	selectedItems,
	handleCheckboxChange,
	handleDetach,
	formatCombinedIds,
	errorMessage,
	setFieldAction,
	dispatch,
	setDetailMembership,
}) => {
	const filter = useSelector((state) => state.Membership.filters);
	const [isSaveButtonVisible, setSaveButtonVisible] = useState(false);
	const [orderTryout, setOrderTryout] = useState([]);
	const [orderCourse, setOrderCourse] = useState([]);
	const dragulaDecorator = (componentBackingInstance) => {
		// console.log(componentBackingInstance, 'componentBackingInstance')
		if (componentBackingInstance) {
			let options = {
				moves: function (el, container, handle) {
					return handle.classList.contains("handle");
				},
			};
			dragula([componentBackingInstance], options).on(
				"drop",
				function (el, target, source, sibling) {
					setSaveButtonVisible(true);
					// get array order id from elements
					const containerChildren = Array.from(target.children);
					//return new format array include id, order
					const order = containerChildren.map((child) => child.id);
					if (title === "courses") {
						setOrderCourse(order.map((id) => parseInt(id)));
					} else if (title === "tryouts") {
						setOrderTryout(order.map((id) => parseInt(id)));
					}
				}
			);
		}
	};
	const handleSaveButtonClick = () => {
		const dataTryout = {
			id: data.id,
			tryouts: orderTryout.map((id) => ({ value: id })),
		};
		const dataCourse = {
			id: data.id,
			courses: orderCourse.map((id) => ({ value: id })),
		};
		const dataToSend = title === "courses" ? dataCourse : dataTryout;
		const apiCall =
			title === "courses"
				? attachDetachCourseMemberships
				: attachDetachTryoutMemberships;
		Swal.fire({
			title: "Konfirmasi",
			text: `Apakah Anda yakin ingin menyimpan urutan ${title}?`,
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Ya, Simpan",
			cancelButtonText: "Batal",
		}).then(async (result) => {
			if (result.isConfirmed) {
				console.log(
					`order ${title}`,
					title === "course" ? orderCourse : orderTryout
				);
				const response = await dispatch(apiCall(dataToSend));
				if (response.payload === true) {
					Swal.fire("Sukses!", `Urutan ${title} berhasil disimpan.`, "success");
					setSaveButtonVisible(false);
					const getMembership = await dispatch(
						fetchMemberships({
							...filter,
							"filter[id]": data.id,
							sort: "",
							page: "",
						})
					);
					dispatch(setDetailMembership(getMembership.payload.data[0]));
				} else {
					Swal.fire("Gagal!", `Urutan ${title} gagal disimpan.`, "error");
				}
				console.log(data.id);
			}
		});
	};

	return (
		<Col
			sm={
				items && items.length > 0 && itemInverse.length > 0
					? "6 order-3"
					: "12 order-2"
			}>
			<Card>
				<CardHeader>
					<H5>
						List {title} membership {detail.title}
					</H5>
				</CardHeader>
				<div>
					{isSaveButtonVisible && (
						<Btn attrBtn={{ color: "warning", onClick: handleSaveButtonClick }}>
							<i className="fa fa-list-ol fa-lg me-2"></i>Save Order
						</Btn>
					)}
					<Table className="table-responsive">
						<thead className="table-light">
							<tr>
								<th scope="col" className="text-center" width="15px">
									<Btn
										attrBtn={{
											color: "link",
											outline: true,
											className: "btn-xs btn-danger",
											onFocus: () => {
												dispatch(
													setFieldAction({
														field: title,
														value: formatCombinedIds,
													})
												);
											},
											onClick: () => {
												if (
													selectedItems.length === 0 &&
													items.length > 1
												) {
													Swal.fire(
														"Gagal!",
														title === "courses"
															? errorMessage.SELECT_COURSE
															: errorMessage.SELECT_TRYOUT,
														"error"
													);
												} else if (
													selectedItems.length === 0 &&
													items.length === 1
												) {
													Swal.fire(
														"Gagal!",
														title === "courses"
															? errorMessage.SELECT_COURSE
															: errorMessage.SELECT_TRYOUT,
														"error"
													);
												} else if (
													selectedItems.length > 0 &&
													items.length > 0
												) {
													handleDetach(
														detail,
														data,
														dispatch,
														setDetailMembership,
														selectedItems
													);
												}
											},
										}}>
										<i className="fa fa-trash fa-lg"></i>
									</Btn>
								</th>
								<th scope="col">{"#"}</th>
								<th scope="col">
									Nama {title === "courses" ? "Course" : "Try out"}
								</th>
								<th scope="col">{"ID "}</th>
								<th scope="col" className="text-center" colSpan="2">
									{"Aksi"}
								</th>
								<th scope="col">
									<AdvanceOrderTryoutCourse
										items={items}
										setOrder={ title === "courses" ? setOrderCourse : setOrderTryout}
										setSaveButtonVisible={setSaveButtonVisible}
									/>
								</th>
							</tr>
						</thead>
						<tbody ref={dragulaDecorator}>
							{items &&
								items.map((item, index) => (
									<tr key={`item-${index}`} id={item.id}>
										<td className="text-center">
											<Input
												className="checkbox_animated"
												id={`detach-${item.id}`}
												type="checkbox"
												checked={selectedItems.includes(item.id)}
												onChange={() => handleCheckboxChange(item.id)}
											/>
										</td>
										<th>{index + 1}</th>
										<td>{item.title}</td>
										<td>{item.id}</td>
										<td className="text-center">
											<Link
												to={
													title === "courses"
														? `${process.env.PUBLIC_URL}/membership/course-detail/${item.id}`
														: `${process.env.PUBLIC_URL}/learning/tryout-detail/${item.id}`
												}
												title="Tekan untuk detail">
												<i className="fa fa-info-circle mx-auto fa-lg"></i>
											</Link>
										</td>
										<td className="text-center">
											<Link
												to={
													title === "courses"
														? `${process.env.PUBLIC_URL}/membership/course-add/${item.id}`
														: `${process.env.PUBLIC_URL}/learning/tryout-add/${item.id}`
												}
												title="Tekan untuk edit"
												onClick={() =>
													title === "courses"
														? handleEditCourse(dispatch, item, setFieldCourse)
														: handleEditTryout(dispatch, item, setFieldTryout)
												}>
												<i className="fa fa-edit mx-auto fa-lg"></i>
											</Link>
										</td>
										<td className="handle text-center">
											<Btn
												attrBtn={{
													color: "link",
													outline: true,
													className: "p-1",
													style: { pointerEvents: "none" },
												}}>
												<i className="fa fa-align-justify mx-auto"></i>
											</Btn>
										</td>
									</tr>
								))}
						</tbody>
						<tfoot>
							<tr>
								<td colSpan={6} className="text-end">
									{isSaveButtonVisible && (
										<Btn
											attrBtn={{
												color: "warning",
												onClick: handleSaveButtonClick,
											}}>
											<i className="fa fa-list-ol fa-lg me-2"></i>Save Order
										</Btn>
									)}
								</td>
							</tr>
						</tfoot>
					</Table>
				</div>
			</Card>
		</Col>
	);
};

export default MembershipList;
