import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../../../AbstractElements';

const DetailPrivateSchedule = () => {
    return (
        <Fragment>
            <Breadcrumbs grandparent="Kelas" parent="Privat" title="Detail Sesi Privat" />
        </Fragment>
    );
};
export default DetailPrivateSchedule;