import React, { Fragment } from 'react';
import { Container, Row, Col, Card } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import MembershipBox from 'Component/Learning/Membership/MembershipBox';
import FindMembershipClass from 'Component/Learning/Membership/Filter/FindMembership';
import { resetState } from 'Features/Learning/Memberships/addMembershipSlice';


const MembershipContain = () => {
  const dispatch = useDispatch();
  const cards = useSelector(state => state.Membership.data.data)

  return (
    <Fragment>
      <Container fluid={true}>
        <Col md="12" className="project-list">
          {cards && (
            <Card className="px-3">
              <FindMembershipClass />
            </Card>
          )}
        </Col>
        <Row className='learning-block'>
          <Col xl="12" className='xl-80'>
            <Row>
              <MembershipBox />
            </Row>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default MembershipContain;