import { createSlice } from "@reduxjs/toolkit";
import { submitTentorMember } from "./Utils/TentorMemberUtils";

const initialState = {
	id: "",
	tentor_id: "",
	name: "",
	total_student: "",
	isLoading: false,
	isUpdate: false,
};

const addTentorMemberSlice = createSlice({
	name: "addTentorMember",
	initialState: initialState,
	reducers: {
		setField: (state, action) => {
			const { field, value } = action.payload;
			state[field] = value;
		},
		resetState: (state) => {
			return initialState;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(submitTentorMember.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(submitTentorMember.fulfilled, (state) => {
				state.isLoading = false;
			})
			.addCase(submitTentorMember.rejected, (state, action) => {
				state.isLoading = false;
				console.log(action.error.message, "error action");
			});
	},
});

export const { setField, resetState } = addTentorMemberSlice.actions;

export default addTentorMemberSlice.reducer;
