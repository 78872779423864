import React, { useEffect, Fragment } from 'react';
import { Breadcrumbs } from '../../../../../AbstractElements';
import PrivateContain from '../../../../../Component/Classes/Schedule/Teaching';

const PrivateScheduleList = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Fragment>
            <Breadcrumbs grandparent="Kelas" parent="Privat" title="Riwayat Mengajar" />
            <PrivateContain />
        </Fragment>
    );
};
export default PrivateScheduleList;